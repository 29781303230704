import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import { fetchUserAnalyticsData } from '../../../../store/actions/dashboard.action';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  DataTableList,
  PTag,
  textFilter,
  dateFilter,
  CaptionElement,
} from '../../../../components/DataTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { addDays } from 'date-fns';
import Input from 'react-select/src/components/Input';

interface IMainProps {
  loadData: (data: any) => void;
  loadCategory: () => void;
  dashboardData: any;
}

class Index extends React.Component<IMainProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      //   daterange: [
      //     {
      //       startDate: new Date(),
      //       endDate: new Date(),
      //       key: 'selection',
      //     },
      //   ],
      fromDate: null,
      toDate: null,
    };
  }

  componentDidMount() {
    this.props.loadData({});
    console.log('this.props.dashboardData', this.props.dashboardData);
  }

  doFilter = () => {
    this.props.loadData({
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
  };
  handleSelect = (item: any) => {
    console.log('item', item);
    console.log('state', this.state.daterange);
    this.setState({ daterange: [item.selection] });
  };
  columns: any = [
    {
      formatter: (cell: any, row: any) => <PTag text={row.id} />,
      text: 'ID',
      dataField: 'id',
      sort: true,
      filter: false,
    },
    // {
    //   formatter: (cell: any, row: any) => (
    //     <a href={`https://tapyourchip.com/${row.id}`} target={'_blank'}>
    //       <PTag text={`https://tapyourchip.com/${row.id}`} />
    //     </a>
    //   ),
    //   text: 'Profile Link',
    //   dataField: 'id',
    //   sort: true,
    //   filter: false,
    // },
    {
      formatter: (cell: any, row: any) => <PTag text={row.name} />,
      text: 'Name',
      dataField: 'name',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.username} />,
      text: 'Username',
      dataField: 'username',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.email} />,
      text: 'Email',
      dataField: 'email',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.countryCode} />,
      text: 'Country Code',
      dataField: 'countryCode',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={
            (row?.callingCode ?? '') +
            ' ' +
            (row?.mobile ? parseInt(row?.mobile).toString() : '')
          }
        />
      ),
      text: 'Mobile',
      dataField: 'mobile',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={row?.created ? moment(row.created).format('MM/DD/YYYY') : null}
        />
      ),
      text: 'Created',
      dataField: 'created',
      sort: true,
      filter: dateFilter({}),
    },
    {
      formatter: (cell: any, row: any) => (
        <Link
          to={{
            pathname: `/dashboard/userDetails/${row.id}`,
          }}
        >
          <Button variant="primary" size="sm">
            View
          </Button>
        </Link>
      ),
      text: 'Action',
      dataField: 'id',
    },
  ];

  //   handleSubmit = (event: any) => {
  //     event.preventDefault();
  //     console.log(event.target.value);
  //   };

  render() {
    // const { dashboardData, } = this.props;

    const { dashboardData } = this.props;

    return (
      <Row>
        <Row>
          <CaptionElement title={'USERS ANALYTICS'} />
          {/* <DateRangePicker
            onChange={(item) => this.handleSelect(item)}
            ranges={this.state.daterange}
          /> */}
          <div>
            <div style={{ flexDirection: 'row' }}>
              <div className="form-group mb-4 col-4">
                <label htmlFor="exampleInputEmail1">Enter start date</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  aria-describedby="dateHelp"
                  placeholder="Enter start date"
                  onChange={(e) => this.setState({ fromDate: e.target.value })}
                />
              </div>
              <div className="form-group mb-4 col-4">
                <label htmlFor="exampleInputEmail1">Enter end date</label>

                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  aria-describedby="dateHelp"
                  placeholder="Enter end date"
                  onChange={(e) => this.setState({ toDate: e.target.value })}
                />
              </div>
            </div>
            <Button
              variant="primary"
              className="shadow-2 mb-4"
              onClick={() => this.doFilter()}
            >
              Filter
            </Button>
          </div>
          {dashboardData.userAlanytics && (
            <DataTableList
              data={dashboardData.userAlanytics}
              columns={this.columns}
            />
          )}
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  dashboardData: state.Dashboard,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadData: (data: any) => dispatch(fetchUserAnalyticsData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);
