import * as Eff from 'redux-saga/effects';
import { fetchfilesWatcher } from './sagas/fileManager.saga';
import { logout, watchLoginAsync } from './sagas/login.saga';
import { initialFetchPostWatcher } from './sagas/post.saga';
import { initialPostCategoryWatcher } from './sagas/postCategory.saga';
import { initialFetchProductWatcher } from './sagas/product.saga';
import { initialProductCategoryWatcher } from './sagas/productCategory.saga';
import { initialFetchDashboardWatcher } from './sagas/dashboard.saga';
import { NFCStockWatcher } from './sagas/NFCStock.saga';
const fork = Eff.fork;
const all = Eff.all;

export default function* ApplicationSaga() {
  yield all([
    fork(watchLoginAsync),
    fork(initialPostCategoryWatcher),
    fork(fetchfilesWatcher),
    fork(initialFetchPostWatcher),
    fork(initialFetchProductWatcher),
    fork(initialProductCategoryWatcher),
    fork(initialFetchDashboardWatcher),
    fork(NFCStockWatcher),
  ]);
}
