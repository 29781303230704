/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { PathApi } from '../../../../config/api.path.config';
import { API } from '../../../../config/api.config';
import {
  successToast,
  warningToast,
  errorToast,
} from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';
import moment from 'moment';
// import Dropdown from '../../../../components/Dropdown';
import Select from 'react-select';

class Index extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true,
      nfcData: null,
      status: null,
      userData: [],
      cuUser: null,
      selectedOption: null,
      seletcOptions: [],
      searchKey: null,
    };
  }

  componentDidMount() {
    console.log('***', this.props.location.state.nfcData);
    // this.setState({ nfcData: this.props.location.state.nfcData });
    this.getNfcData();
  }

  getNfcData = () => {
    this.setState({ isLoading: true });
    API.get(PathApi.getNfcById + this.props.location.state.nfcData.id)
      .then((data: any) => {
        console.log('serial***', data.data.data);
        if (data.data.ack == 1) {
          this.setState({ nfcData: data.data.data }, () => this.getUserData());
        } else {
          errorToast('Unable to load NFC data');
        }
      })
      .catch((error: any) => {
        console.log('error', error);
        this.setState({ isLoading: false });
        errorToast('Unable to load NFC data');
      });
  };

  getUserData = (query = '') => {
    console.log('oninput change', query);
    const routeUser = this.state.nfcData?.user ?? null;

    const postData = {
      query: query,
      limit: 10,
    };
    API.post(PathApi.userSearch, postData)
      .then((data: any) => {
        console.log('getUserData data', data);
        let userData = data.data;
        let selectedOption = null;
        if (userData.length > 0) {
          if (routeUser) {
            const userExist = userData.find((e: any) => e.id == routeUser.id);
            userExist.length == 0 && userData.push(routeUser);
            console.log('userData', userExist, userData);
          }

          this.setState({
            userData,
            seletcOptions: userData.map((e: any) => {
              if (e.id == this.state.nfcData?.user?.id) {
                selectedOption = {
                  label:
                    (e?.name ?? 'NA') +
                    ' - ' +
                    (e?.username ?? 'NA') +
                    ' - ' +
                    (e?.email ?? 'NA'),
                  value: e.id,
                };
              }
              return {
                label:
                  (e?.name ?? 'NA') +
                  ' - ' +
                  (e?.username ?? 'NA') +
                  ' - ' +
                  (e?.email ?? 'NA'),
                value: e.id,
              };
            }),
            selectedOption,
          });
        }
      })
      .catch((error: any) => {
        this.setState({ userData: [] });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateUser = (dlink = true, userId = null) => {
    this.setState({ isLoading: true });
    console.log('userId', userId);

    const postdata: any = dlink
      ? {
          nfcId: this.state.nfcData.id,
          status: 'DELINK',
        }
      : {
          nfcId: this.state.nfcData.id,
          userId: userId,
          status: 'LINK',
        };
    console.log('postdata', postdata);

    API.post(PathApi.nfcUpdateUser, postdata)
      .then((data: any) => {
        console.log('data', data);
        successToast(dlink ? 'Nfc reset successful' : 'Nfc user changed.');
        dlink ? this.props.history.goBack() : this.getNfcData();
      })
      .catch((error: any) => {
        console.log('error', error);
        this.setState({ isLoading: false });
        errorToast(
          dlink ? 'Unable to reset Nfc ' : 'Unable to update Nfc User'
        );
      });
  };

  handleChange = (selectedOption: any) => {
    this.setState({ selectedOption, cuUser: selectedOption.value }, () =>
      console.log(`handleChange:`, this.state.selectedOption)
    );
  };
  onInputChange = (searchKey: any) => {
    console.log('searchKey', searchKey);
    if (searchKey && searchKey.length > 0) {
      this.setState({ searchKey }, () => this.getUserData(searchKey));
    }
  };

  render() {
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    const { nfcData, userData } = this.state;
    return (
      <Container>
        <Col md="auto">
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: 20, marginBottom: 10 }}
          >
            <h1>NFC Details</h1>
          </Row>
          <div className="col-md-12">
            <p>
              <span style={{ fontWeight: 'bold' }}> Serial No:- </span>
              {nfcData?.serialNo}
            </p>

            <p>
              <span style={{ fontWeight: 'bold' }}> Password:- </span>
              {nfcData?.password}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Active/Deactive:- </span>
              {nfcData?.isActive ? 'YES' : 'NO'}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Stock:- </span>
              {nfcData?.inStock ? 'YES' : 'NO'}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Category Type:- </span>
              {nfcData?.categoryType}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Product Id:- </span>
              {nfcData?.productId}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Name:- </span>
              {nfcData?.user?.name}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> User name:- </span>
              {nfcData?.user?.username}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Date Of Arrival:- </span>
              {nfcData?.dateOfArrival
                ? moment(nfcData.dateOfArrival).format('DD/MM/YYYY')
                : null}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}> Created:- </span>
              {nfcData?.created
                ? moment(nfcData.created).format('DD/MM/YYYY')
                : null}
            </p>
            <div className="col-sm-12" style={{ margin: 5 }}>
              <div style={{ fontWeight: 'bold' }} className="col-sm-3">
                Change User:-{' '}
              </div>
              <div className="col-sm-5">
                {this.state.seletcOptions.length > 0 && (
                  <Select
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                    options={this.state.seletcOptions}
                    onInputChange={this.onInputChange}
                  />
                )}
                {/* <select
                  className="form-select"
                  onChange={(e) => {
                    console.log('cg', e.target.value);
                    this.setState({ cuUser: e.target.value });
                  }}
                >
                  <option value="">Select</option>
                  {userData.length > 0 &&
                    userData.map((e: any, i: any) => {
                      return (
                        <option
                          key={i}
                          value={e.id}
                          selected={e.id == nfcData?.user?.id ? true : false}
                        >
                          {e?.name ?? e?.username}
                        </option>
                      );
                    })}
                </select> */}
              </div>
            </div>
            <div className="col-12" style={{ margin: 5 }}>
              <button
                disabled={
                  this.state.cuUser != null && this.state.cuUser.length == 0
                }
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.updateUser(false, this.state.cuUser)}
              >
                Update User
              </button>
            </div>
            <div className="col-12" style={{ marginTop: 15 }}>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.updateUser()}
              >
                Reset NFC
              </button>
            </div>
          </div>
        </Col>
        <Row style={{ height: 200 }}></Row>
      </Container>
    );
  }
}
export default Index;
