export enum DashboardConstant {
  FETCH_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS',
  FETCH_ERROR = 'FETCH_ALERT_ERROR',
  FETCH = 'FETCH_DASHBOARD_DATA',
  FETCH_TAP_COUNT = 'FETCH_TAP_COUNT',
  FETCH_TAP_SUCCESS = 'FETCH_TAP_SUCCESS',

  FETCH_USER_ANALYTICS = 'FETCH_USER_ANALYTICS',
  FETCH_USER_ANALYTICS_SUCCESS = 'FETCH_USER_ANALYTICS_SUCCESS',
}
