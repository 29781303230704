import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavLeft from './navleft';
import NavRight from './navright';
import { NavConstant } from '../../../../store/actionTypes/nav.types.d';
import { GiCaveEntrance } from 'react-icons/all';
import logo from '../../../../assets/images/DE.png'

interface IStateProps {
  headerFixedLayout: string;
  collapseMenu: boolean;
  headerBackColor: string;
  rtlLayout: boolean;
}

interface IDispatchProps {
  onToggleNavigation: () => void;
}

class TopNav extends Component<IStateProps & IDispatchProps, any> {
  render() {
    let headerClass = [
      'navbar',
      'pcoded-header',
      'navbar-expand-lg',
      this.props.headerBackColor,
    ];
    if (this.props.headerFixedLayout) {
      headerClass = [...headerClass, 'headerpos-fixed'];
    }

    let toggleClass = ['mobile-menu'];
    if (this.props.collapseMenu) {
      toggleClass = [...toggleClass, 'on'];
    }

    return (
      <>
        <header className={headerClass.join(' ')}>
          <div className="m-header">
            <a
              className={toggleClass.join(' ')}
              id="mobile-collapse1"
              href="#"
              onClick={this.props.onToggleNavigation}
            >
              <span />
            </a>
            <a  className="b-brand"style={{paddingLeft:'5%'}}>
              <div className="b-bg text-dark" >
              <img src={logo} style={styles}/>
              </div>
            </a>
          </div>
          <a className="mobile-menu" id="mobile-header" href="#">
            <i className="feather icon-more-horizontal" />
          </a>
          <div className="collapse navbar-collapse">
            <NavLeft />
            <NavRight rtlLayout={this.props.rtlLayout} />
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    rtlLayout: state.NavReducer.rtlLayout,
    headerBackColor: state.NavReducer.headerBackColor,
    headerFixedLayout: state.NavReducer.headerFixedLayout,
    collapseMenu: state.NavReducer.collapseMenu,
  };
};

const styles = {
  width: '500%',
  height:'120%',
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onToggleNavigation: () =>
      dispatch({
        type: NavConstant.COLLAPSEM,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
