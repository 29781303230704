/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Row, Col, Image, Table, Container } from 'react-bootstrap';
import { PathApi } from '../../../../config/api.path.config';
import { API } from '../../../../config/api.config';
import {
  successToast,
  warningToast,
  errorToast,
} from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';

class Index extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      userData: null,
      directApp: null,
      isLoading: true,
      name: null,
      address: null,
      userBio: null,
    };
  }

  componentDidMount() {
    console.log('***', this.props.match.params);
    this.getUserData();
  }

  getUserData = () => {
    API.get(PathApi.usersByid + this.props.match.params.userId)
      .then((data: any) => {
        console.log('data', data);
        let userData = data.data;
        let directApp = null;
        if (userData?.isDirect) {
          directApp = userData[
            userData.profileTabType == 'Personal'
              ? 'socialProfiles'
              : 'businessProfiles'
          ].filter((e: any) => e.id == userData.activeDirect)[0];
        }
        // successToast('User data loaded');
        this.setState({
          userData,
          isLoading: false,
          directApp,
          name: userData.name,
          address: userData.address,
          userBio: userData.userBio,
        });
      })
      .catch((error: any) => {
        // console.log(error);
        this.setState({ userData: null, isLoading: true });
        warningToast('Error in loading user data NFC data');
      });
  };

  updateUserData = () => {
    this.setState({ isLoading: true });

    const postdata = {
      name: this.state.name,
      address: this.state.address,
      userBio: this.state.userBio,
    };
    API.patch(PathApi.userUpdateById + this.props.match.params.userId, postdata)
      .then((data: any) => {
        console.log('data', data);
        successToast('User data Updated');
        this.getUserData();
      })
      .catch((error: any) => {
        console.log('error', error);
        this.setState({ isLoading: false });
        errorToast('Error in updating user data');
      });
  };

  render() {
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    const { userData } = this.state;
    return (
      <Container>
        <Col md="auto">
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: 20, marginBottom: 10 }}
          >
            <Image
              src={userData.profilePic}
              thumbnail
              style={{ width: 200, height: 200 }}
            />
          </Row>
          <form className="row g-3">
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">
                Name
              </label>
              <input
                placeholder="Name"
                type="email"
                className="form-control"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">
                User Name
              </label>
              <input
                placeholder="User Name"
                type="email"
                className="form-control"
                value={userData?.username}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label">
                Email
              </label>
              <input
                placeholder="Email"
                type="email"
                className="form-control"
                value={userData?.email}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputAddress" className="form-label">
                Country Code
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={userData?.countryCode}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputAddress" className="form-label">
                Mobile
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={userData?.mobile}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputAddress" className="form-label">
                Profile Link
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Profile Link"
                value={`https://tapyourchip.com/${userData.id}`}
                disabled
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputAddress" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={this.state.address}
                onChange={(e) => this.setState({ address: e.target.value })}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputAddress" className="form-label">
                User Bio
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="User Bio"
                value={this.state.userBio}
                onChange={(e) => this.setState({ userBio: e.target.value })}
              />
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.updateUserData()}
              >
                Update
              </button>
            </div>
          </form>
        </Col>
        <Row style={{ height: 200 }}></Row>
      </Container>
    );
  }
}
export default Index;
