import React from 'react';
import LoginForm from './loginform';
import { requestLogin } from '../../../store/actions/login.action';
import { connect } from 'react-redux';
import { ILoginRequest } from '../../../store/models/api/Login.api.model';
import { Dispatch } from 'redux';

class Login extends React.Component<any, any> {
  handleLogin = (values: any) => {
    return this.props.doLogin(values);
  };

  render() {
    return (
      <LoginForm
        onSubmit={this.handleLogin}
        initialValues={{ email: '', password: '' }}
        pristine={this.props.loginStore.pristine}
        submitting={this.props.loginStore.submitting}
      />
    );
  }
}
const mapStateToProps = (state: any, ownProps: any) => {
  return { loginStore: state.loginReducer };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  doLogin: (request: ILoginRequest) => dispatch(requestLogin(request)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
