import React, { Component } from 'react';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PageTitle from '../../../../components/Dashboard/common/PageTitle';
import { sanitizeNU } from '../../../../utils/stringHelpers';
import {
  fetchProductCategoryRequest,
  loadProductCategory,
  productCategoryRequest,
} from '../../../../store/actions/product-category.action';
import CategoryForm from '../../../../components/Dashboard/common/CategoryForm';
import { Container, Form as BForm } from 'react-bootstrap-v5';

type props = {
  categories: any;
  categoryRequest: (values: any) => Promise<{}>;
  categoryLoad: (values: any) => void;
  reset?: () => void;
  getCategoryRequest: () => void;
};

type state = {
  selected: string;
  loadPost: any;
  editMode: boolean;
};

class ProductCategoryList extends Component<props, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      selected: '',
      loadPost: {},
      editMode: false,
    };
    this.editSelected = this.editSelected.bind(this);
  }
  componentDidMount() {
    this.props.getCategoryRequest();
  }

  handleSubmit = (values: any) => {
    values.category.parentId =
      values.category?.parentId.value !== undefined
        ? values.category.parentId.value
        : '0';
    this.props.categoryRequest(sanitizeNU(values));
    this.resetForm();
    return;
  };

  editSelected(e: any) {
    if (!this.state.editMode) {
      const item = JSON.parse(e.target.value);
      this.setState({ selected: item.id, editMode: true });
      this.props.categoryLoad(item);
    } else {
      this.resetForm();
      this.setState({ selected: '', editMode: false });
    }
  }
  resetForm = () => {
    this.props.categoryLoad({ parentId: 0 });
    this.setState({ selected: '', editMode: false });
  };
  findCategory(value: string, items: any): any {
    if (items && items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].id === value) {
          return items[i];
        }
        if (items[i].hasOwnProperty('childerns')) {
          return this.findCategory(value, items[i].childerns);
        }
      }
    }
  }

  getCard = (item: any, parent: string = '') => {
    return (
      <>
        <Col md={3}>
          <Card.Img src={item.image} />
        </Col>
        <Col md={3}>{item.name}</Col>
        <Col md={3}>{item.slug}</Col>
        <Col md={2}>{item.parentId == 0 ? 'Self' : parent}</Col>
        <Col md={1}>
          <Form.Group className="text-left">
            <Form.Check
              type="checkbox"
              label="Check me out"
              id={`checkbox-${item.title}`}
              className="checkbox checkbox-fill d-inline"
            >
              <Form.Check.Input
                name={item.id}
                type="checkbox"
                checked={this.state.selected == item.id}
                value={JSON.stringify(item)}
                onChange={this.editSelected}
              />
              <Form.Check.Label className="cr"></Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
      </>
    );
  };
  getChilds = (item: any, parent: string = '') => {
    return (
      <div key={item.id}>
        <Card className="my-2">
          <Card.Body style={{ padding: '1rem' }}>
            <Row>{this.getCard(item, parent)}</Row>
          </Card.Body>
        </Card>
        {item.childerns &&
          item.childerns.map((child: any) => this.getChilds(child, item.title))}
      </div>
    );
  };

  render() {
    const { categories } = this.props.categories.categoryList;
    const { category } = this.props.categories.category;
    let initialValues = category;
    return (
      <Container>
        <Row noGutters className="page-header py-4">
          <PageTitle
            subtitle=" Add/Edit"
            title="Product Categories"
            className=""
          />
        </Row>
        <Row>
          <Col xs={12} md={5}>
            <Card>
              <CategoryForm
                onSubmit={this.handleSubmit}
                initialValues={{
                  category: {
                    id: initialValues?.id,
                    slug: initialValues?.slug,
                    image: initialValues?.bannerImage,
                    title: initialValues?.title,
                    categoryTypeId: initialValues?.categoryTypeId
                      ? initialValues?.categoryTypeId
                      : this.props.categories.categoryTypeId,
                    parentId: initialValues?.parentId,
                  },
                }}
                {...{
                  editMode: this.state.editMode,
                  category: categories,
                  type: 'product',
                  reset: this.resetForm,
                }}
              />
            </Card>
          </Col>
          <Col xs={12} md={7}>
            <Card>
              <Card.Header>All Categories</Card.Header>
              <Card.Body style={{ height: 500, overflowY: 'scroll' }}>
                {categories &&
                  categories.map((item: any) => this.getChilds(item))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  categories: state.ProductCategories,
});

const mapDispatchToProps = (dispatch: any) => ({
  getCategoryRequest: () => dispatch(fetchProductCategoryRequest()),
  categoryRequest: (values: any) => dispatch(productCategoryRequest(values)),
  categoryLoad: (values: any) =>
    dispatch(loadProductCategory({ category: values })),
});
// reset: () => dispatch(reset('category')),}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategoryList);
