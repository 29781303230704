import React from 'react';
import { Container, Row, Button, Form as Bform } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FiUnlock } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import {
  aol,
  composeValidators,
  email,
  required,
} from '../../../lib/validtionhelper';
import { FieldInput } from '../../../lib/formhelper';
import { FaApper, GiCaveEntrance, RiAdminLine } from 'react-icons/all';
import { Field, Form } from 'react-final-form';
import unnamed from '../../../assets/images/unnamed.png';
import { colors } from 'react-select/src/theme';
import isValid from 'date-fns/esm/fp/isValid/index.js';
type Iprops = {
  handleSubmit: () => void;
  onSubmit(value: any): (value: any) => void;
  alertStore: any;
  dispatch: any;
  reset: any;
  pristine: boolean;
  submitting: boolean;
  valid: any;
  error: any;
};
const emailIsUnique = async (email: string) => {
  await wait(300);
  if (email !== 'tycconsol@gmail.com') {
    return 'this is not an admin email';
  }
};

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
class LoginForm extends React.Component<Iprops> {
  constructor(props: Iprops) {
    super(props);
  }
  render() {
    const { onSubmit, handleSubmit, alertStore, submitting, valid, pristine } =
      this.props;
    console.log(this.props);
    return (
      <Container fluid className="px-0">
        <div className="auth-wrapper justify-content-center">
          <div className="auth-content">
            <div className="auth-bg">
              <span className="r" />
              <span className="r s" />
              <span className="r s" />
              <span className="r s" />
              <span className="r s" />
              <span className="r" />
            </div>
            <div className="card">
              <div className="card-body text-center">
                <div className="mb-4">
                  <img src={unnamed} />
                </div>
                <h3 className="mb-4 auth-icon-text">Login</h3>

                {alertStore.message && (
                  <div className={`mb-3 alert ${alertStore.tag}`}>
                    {alertStore.message}
                  </div>
                )}
                <Form onSubmit={onSubmit}>
                  {({ handleSubmit, invalid }) => (
                    <Bform onSubmit={handleSubmit}>
                      <Row>
                        <Field
                          type="email"
                          component={FieldInput}
                          name="email"
                          validate={emailIsUnique}
                          placeholder="youremail@example.com"
                          warning={aol}
                          className="input-group mb-3"
                        />
                        <Field
                          name="password"
                          component={FieldInput}
                          type="password"
                          validate={required}
                          placeholder="Password"
                          className="input-group mb-4"
                        />
                      </Row>
                      {/* <Row className="text-left">
                    <Field
                      type="checkbox"
                      name="rememberme"
                      label="Check me out"
                      id="checkbox-fill-a1"
                      className="checkbox checkbox-fill d-inline"
                    >
                    <option value="FF0000">Red</option>
                    </Field>
                    <label className="cr" htmlFor="rememberme" >
                    Remember Me
                    </label>
                  </Row> */}
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={submitting}
                        className="shadow-2 mb-4"
                      >
                        {submitting ? (
                          <span>
                            <i className="fa fa-spin fa-spinner">Logging In</i>
                          </span>
                        ) : (
                          <span>Login</span>
                        )}
                      </Button>
                    </Bform>
                  )}
                </Form>

                {/* <p className="mb-2 text-muted">
                  Forgot password?
                  <NavLink to="/auth/reset-password-1">Reset</NavLink>
                </p>
                <p className="mb-0 text-muted">
                  Don’t have an account?
                  <NavLink to="/auth/signup-1">Signup</NavLink>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  alertStore: state.AlertReducer, // some data from the server
});
export default connect(mapStateToProps)(LoginForm);
function sleep(arg0: number) {
  throw new Error('Function not implemented.');
}

// function emailval(emails: string) {

//     if(emails=='test@hotmail.com'){
//       return true;
//   }
//   return false;

// }
