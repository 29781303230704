import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { errorToast, successToast, warningToast } from '../../lib/toasthelper';
import {
  fetchNFCService,
  deleteService,
  uploadService,
} from '../../services/NFCStock.service';
import { loadNFCData } from '../actions/NFCStock.action';
import { NFCStockConstant } from '../actionTypes/NFCStock.types.d';
import { IFetchGetResponse } from '../models/api/Dashboard.api.model';

function* fetchNFCSaga(payload?: any) {
  try {
    const response: IFetchGetResponse = yield call(fetchNFCService);
    console.log('response', response);

    if (response.status === 200) {
      const data: any = response.data;
      yield put(loadNFCData(data));
      successToast('NFC List loaded');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    warningToast('Error in Fetching NFC List. Reinitialize Process');
  }
}

function* deleteSaga(payload?: any) {
  // try {
  //   const response: IFetchGetResponse = yield call(deleteService, payload);
  //   console.log('response', response);
  //   if (response.status === 200) {
  //     const data: any = response.data;
  //     yield put(loadUserAnalyticsData(data));
  //     successToast('User analytics data loaded');
  //   } else {
  //     throw response.data.error;
  //   }
  // } catch (error: any) {
  //   warningToast('Error in Fetching Tap count. Reinitialize Process');
  // }
}

function* uploadSaga(payload?: any) {
  // try {
  //   const response: IFetchGetResponse = yield call(uploadService, payload);
  //   console.log('response Tap', response);
  //   if (response.status === 200) {
  //     const data: any = response.data;
  //     yield put(loadTapCountData(data));
  //     successToast('User Tap count loaded');
  //   } else {
  //     throw response.data.error;
  //   }
  // } catch (error: any) {
  //   warningToast('Error in Fetching Tap count. Reinitialize Process');
  // }
}

export function* NFCStockWatcher() {
  yield takeEvery(NFCStockConstant.FETCH_NFC_DATA, fetchNFCSaga);
  yield takeEvery(NFCStockConstant.DELETE_NFC, deleteSaga);
  yield takeEvery(NFCStockConstant.NFC_STOCK_UPLOAD, uploadSaga);
}
