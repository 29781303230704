import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';

export function fetchUsersService(filter?: any) {
  return API.get(PathApi.users)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function fetchTapsService(payload: any = {}) {
  console.log('fetchTapsService', payload);

  return API.post(PathApi.getnfcTapCountFilter, payload.payload)
    .then((data: any) => {
      console.log('data', data);
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function fetchUserAnalyticsService(payload: any = {}) {
  console.log('payload', payload);

  return API.post(PathApi.usersByFilterDate, payload.payload)
    .then((data: any) => {
      console.log('data', data);
      return data;
    })
    .catch((error: any) => {
      console.log(error);

      return error.response;
    });
}
