import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';
import { ILoginRequest } from '../store/models/api/Login.api.model';

export default function loginApi(params: ILoginRequest) {
  return API.post(PathApi.user, params)
    .then((data: any) => {
      return data;
    
    })
    .catch((error: any) => {
      return error.response;
    });
}
