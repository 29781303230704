import { PostCategoryConstant } from "../actionTypes/post-category.types.d";
import { IPostCategoryAction } from "../models/actions/PostCategory.model";

export const categoryRequest = (payload: any): IPostCategoryAction => ({
  type: PostCategoryConstant.REQUEST,
  category: {
    category: payload.category,
  },
});

export const Error = (message: any): IPostCategoryAction => ({
  type: PostCategoryConstant.ERROR,
  message: message,
});

export const Success = (message: any): IPostCategoryAction => ({
  type: PostCategoryConstant.SUCCESS,
  message: message,
});

export const fetchPostCategoryRequest = (
  ): IPostCategoryAction => ({
    type: PostCategoryConstant.FETCH_REQUEST,
  });

export const fetchPostCategorySuccess = (payload: any): IPostCategoryAction => ({
  type: PostCategoryConstant.FETCH_SUCCEED,
  categories:payload 
});  
export const fetchPostCategoryError = (message: any): IPostCategoryAction => ({
    type: PostCategoryConstant.FETCH_ERROR,
    message:message  
  });

export const loadCategory = (payload: any): IPostCategoryAction => ({
  type: PostCategoryConstant.LOAD_CATEGORY,
  category: payload.category,
});




