import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import {
  DataTableList,
  PTag,
  textFilter,
  selectFilter,
  CaptionElement,
  dateFilter,
} from '../../../../components/DataTable';
import { fetchNFCData } from '../../../../store/actions/NFCStock.action';
import { PathApi } from '../../../../config/api.path.config';
import { API } from '../../../../config/api.config';
import {
  errorToast,
  successToast,
  warningToast,
} from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';
import moment from 'moment';
import { loadOrdersData } from '../../../../store/actions/Orders.action';
import { Link } from 'react-router-dom';

interface IMainProps {
  loadData: (data: any) => void;
  loadCategory: () => void;
  Orders: any;
}

class Index extends React.Component<IMainProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      selectedIDs: [],
    };
  }

  componentDidMount() {
    this.getOrders();
  }
  serialize = (obj: any, prefix: any = ''): any => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  };
  deleteOrder = (orderId: string) => {
    this.setState({ isLoading: true });
    API.delete(PathApi.orders + '/' + orderId)
      .then((data: any) => {
        console.log('data', data);
        successToast('Order data Deleted');
        this.getOrders();
      })
      .catch((error: any) => {
        console.log('error', error);
        errorToast('Error in deleting order data');
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  getOrders = (page = 0, sizePerPage = 10, isloading = true) => {
    // const pageNo = page > 0 ? page - 1 : page;
    // const urnEncodeData = this.serialize({
    //   filter: {
    //     offset: pageNo,
    //     limit: sizePerPage,
    //   },
    // });
    // console.log('urnEncodeData', urnEncodeData);
    isloading && this.setState({ isLoading: true });
    // API.get(PathApi.orders + '?' + urnEncodeData)
    API.get(PathApi.orders)
      .then((data: any) => {
        console.log('order data', data);
        const loadData = {
          ...data.data,
          // offset: pageNo + 1,
          // limit: sizePerPage,
        };
        this.props.loadData(loadData);
        isloading && successToast('Order Loaded Successfully');
        isloading && this.setState({ isLoading: false });
      })
      .catch((error: any) => {
        isloading && this.setState({ isLoading: false });
        warningToast('Error in Loading Order data');
      });
  };
  onTableChangeData = (page: number, sizePerPage: number) => {
    console.log('dsf', page, sizePerPage);
    this.getOrders(page, sizePerPage, false);
  };

  downloadInvoice = (url: string) => {
    if(url) {
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', 'true');
      a.click();
      a.parentNode?.removeChild(a);
    }
  }

  // {
  //   ORDER_RECEIVED: 'ORDER_RECEIVED',
  //   PROCESSING_ORDER: 'PROCESSING_ORDER',
  //   IN_TRANSIST: 'IN_TRANSIST',
  //   SUCCESSFUL: 'SUCCESSFUL',
  //   CANCELLED: 'CANCELLED',
  // }

  columns: any = [
    {
      text: 'Order Id',
      dataField: 'orderId',
      sort: true,
      filter: textFilter(),
    },
    {
      text: 'Email',
      dataField: 'user.email',
      sort: true,
      filter: textFilter(),
    },
    {
      text: 'Payment Status',
      dataField: 'paymentStatus',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag text={parseFloat(row.totalAmount).toFixed(2)} />
      ),
      text: 'Total Amount',
      dataField: 'totalAmount',
      sort: true,
      filter: textFilter(),
    },
    {
      text: 'Status',
      dataField: 'status',
      sort: true,
      // filter: textFilter(),
      filter: selectFilter({
        options: {
          ORDER_RECEIVED: 'ORDER_RECEIVED',
          PROCESSING_ORDER: 'PROCESSING_ORDER',
          IN_TRANSIST: 'IN_TRANSIST',
          SUCCESSFUL: 'SUCCESSFUL',
          CANCELLED: 'CANCELLED',
        },
      }),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={
            row?.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : null
          }
        />
      ),
      text: 'Created At',
      dataField: 'createdAt',
      sort: true,
      // filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <Col>
          <Link
            to={{
              pathname: `/dashboard/orders/${row.id}/edit`,
            }}
          >
            <Button variant="primary" size="sm">
              View
            </Button>
          </Link>
          <Button
            variant="danger"
            size="sm"
            onClick={() => this.deleteOrder(row.id)}
          >
            Delete
          </Button>
          { row.invoiceUrl && <Button variant="primary" size="sm" onClick={() => this.downloadInvoice(row.invoiceUrl)}>
            Download
          </Button>}
        </Col>
      ),
      text: 'Action',
      dataField: 'id',
      csvExport: false,
    },
  ];

  render() {
    const { Orders } = this.props;
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    return (
      <Row>
        <Row>
          <CaptionElement title={'ORDER LIST'} />
          <DataTableList
            data={Orders.orderlist}
            columns={this.columns}
            loading={this.state.isLoading}
            exportCSV={true}
            // onTableChangeData={this.onTableChangeData}
            // paginationData={{
            //   page: Orders.offset,
            //   totalSize: Orders.total,
            //   sizePerPage: Orders.limit,
            // }}
          />
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  Orders: state.Orders,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadData: (data: any) => dispatch(loadOrdersData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);
