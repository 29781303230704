import {
  Container,
  Row,
  Col,
  Form as BForm,
  Card,
  Tab,
  Tabs,
  Nav,
} from 'react-bootstrap-v5';
import PageTitle from '../../../components/Dashboard/common/PageTitle';
import Editor from '../../../components/Dashboard/post/add-new-post/Editor';
import SidebarCategories from '../../../components/Dashboard/post/add-new-post/SidebarCategories';
import ReactMediaLibraryWrapper from '../../../components/Dashboard/post/add-new-post/SideBarMediaUpload';
import React from 'react';
import { connect } from 'react-redux';
import SidebarActions from '../../../components/Dashboard/post/add-new-post/SidebarActions';
import { isEmpty } from 'lodash';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import slug from 'slug';
import { FaBraille, FaWrench } from 'react-icons/fa';
import { required } from '../../../lib/validtionhelper';
import { FieldInput } from '../../../lib/formhelper';
import SideBarMediaUpload from '../../../components/Dashboard/post/add-new-post/SideBarMediaUpload';

interface IProps {
  onSubmit: (value: any) => void;
  reset: any;
  pristine: any;
  submitting: boolean;
  valid: any;
  error: any;
  editMode: boolean;
  initialValues: any;
  categories: any;
}

interface IState {
  imgSrc?: string;
}

class ProductForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      imgSrc: '',
    };
  }

  onMediaChange = (e: any) => {
    this.setState({ imgSrc: e });
  };

  componentDidMount() {
    if (!isEmpty(this.props.initialValues.product)) {
      this.setState({ imgSrc: this.props.initialValues.product.bannerImage });
    }
  }

  calculateSlug = createDecorator({
    field: 'product.title',
    updates: {
      'product.slug': (titleValue, allValues) =>
        titleValue ? slug(titleValue, '-') : titleValue,
    },
  });

  onSubmit = (values: any) => {
    console.log(values);
    this.props.onSubmit(values);
  };
  render() {
    const { onSubmit, submitting, valid, editMode, initialValues, categories } =
      this.props;
    console.log(initialValues);
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Form
          onSubmit={this.onSubmit}
          decorators={[this.calculateSlug]}
          className="align-self-center"
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={initialValues}
        >
          {({ form, handleSubmit, invalid, values }) => (
            <BForm onSubmit={handleSubmit}>
              {/* Page Header */}
              <Row noGutters className="page-header py-4">
                <PageTitle
                  sm="4"
                  title="product"
                  subtitle={editMode ? 'Edit' : 'Add New'}
                  className=""
                />
              </Row>
              <Row>
                {/* Editor */}
                <Col lg="9" md="12">
                  <Editor type="product" />
                  <Card>
                    <Card.Header>
                      <h4>Product Data</h4>
                    </Card.Header>
                    <Card.Body style={{ paddingTop: 10 }}>
                      <Tab.Container
                        id="product-data"
                        defaultActiveKey="general"
                      >
                        <Row>
                          <Col sm={3}>
                            <Nav variant="tabs" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="general">
                                  <span>
                                    <FaWrench /> General
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">
                                  <span>
                                    {' '}
                                    <FaBraille /> Attributes
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey="general">
                                <BForm.Group as={Row}>
                                  <BForm.Label column sm="4">
                                    Regular Price
                                  </BForm.Label>
                                  <Col sm={'8'}>
                                    <Field
                                      type="number"
                                      className="input-group mb-4"
                                      placeholder="0.0"
                                      validate={required}
                                      component={FieldInput}
                                      name={`product.regularPrice`}
                                    />
                                  </Col>
                                </BForm.Group>
                                <BForm.Group as={Row}>
                                  <BForm.Label column sm="4">
                                    Sale Price
                                  </BForm.Label>
                                  <Col sm={'8'}>
                                    <Field
                                      type="number"
                                      className="input-group mb-4"
                                      placeholder="0.0"
                                      validate={required}
                                      component={FieldInput}
                                      name={`product.salePrice`}
                                    />
                                  </Col>
                                </BForm.Group>
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <p className="text-center">Coming Soon</p>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Card.Body>
                  </Card>
                </Col>
                {/* Sidebar Widgets */}
                <Col lg="3" md="12">
                  <SidebarActions
                    title={'Actions'}
                    submitting={submitting}
                    editMode={editMode}
                    form={form}
                    type={'product'}
                  />
                  <SidebarCategories
                    categories={categories}
                    currentCategories={initialValues.productCategories}
                    type={`productCategories`}
                  />
                  <ReactMediaLibraryWrapper
                    imgSrc={this.state.imgSrc}
                    onChange={this.onMediaChange}
                    title="Banner Image"
                    type="product"
                    form={form}
                  />
                </Col>
              </Row>
            </BForm>
          )}
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  alertStore: state.AlertReducer, // some data from the server
  editMode: ownProps.editMode,
  initialValues: ownProps.initialValues,
  categories: state.ProductCategories.categoryList.categories,
});

export default connect(mapStateToProps)(ProductForm);
