import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Table, Button, Dropdown, Form } from 'react-bootstrap';
import { fetchTapCountData } from '../../../../store/actions/dashboard.action';
// import DataTable, { defaultThemes } from 'react-data-table-component';
import { DataTableList, PTag } from '../../../../components/DataTable';
import { countryList, getCountry } from '../../../../utils/CountryHelpers';
import { API } from '../../../../config/api.config';
import { PathApi } from '../../../../config/api.path.config';

interface IMainProps {
  loadData: (data: any) => void;
  loadCategory: () => void;
  dashboardData: any;
  tapCount: any;
}

class Index extends React.Component<IMainProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      country: null,
      city: null,
      cityList: [],
    };
  }
  componentDidMount() {
    this.props.loadData({});
  }
  columns: any = [
    {
      cell: (row: any) => <PTag text={row.id} />,
      name: 'ID',
      sortable: true,
    },
    {
      cell: (row: any) => (
        <a href={`https://tapyourchip.com/${row.id}`} target={'_blank'}>
          <PTag text={`https://tapyourchip.com/${row.id}`} />
        </a>
      ),
      name: 'Profile Link',
      sortable: true,
    },
    {
      cell: (row: any) => <PTag text={row.firstName} />,
      name: 'First Name',
      sortable: true,
    },
    {
      cell: (row: any) => <PTag text={row.lastName} />,
      name: 'Last Name',
      sortable: true,
    },
    {
      cell: (row: any) => <PTag text={row.username} />,
      name: 'Username',
      sortable: true,
    },
    {
      cell: (row: any) => <PTag text={row.email} />,
      name: 'Email',
      sortable: true,
    },
    {
      cell: (row: any) => <PTag text={row.countryCode} />,
      name: 'Country Code',
      sortable: true,
    },
    {
      cell: (row: any) => (
        <PTag text={(row?.callingCode ?? '') + ' ' + (row.mobile ?? '')} />
      ),
      name: 'Mobile',
      sortable: true,
    },
  ];

  doFilter = () => {
    this.props.loadData({
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      country: this.state.country,
      city: this.state.city,
    });
  };
  getCity = (country: any) => {
    console.log('this', country);

    this.setState({ country, city: null });
    API.get(PathApi.citylistsByCountry + country)
      .then((data: any) => {
        console.log(data);
        this.setState({ cityList: data.data });
      })
      .catch((error: any) => {
        console.log(error);
        //  error.response;
      });
    // this.props.loadData({
    //   fromDate: this.state.fromDate,
    //   toDate: this.state.toDate,
    //   country: this.state.country,
    //   city: this.state.city,
    // });
  };

  render() {
    const { dashboardData } = this.props;
    return (
      <Row>
        <Row>
          <div>
            <div style={{ flexDirection: 'row' }}>
              <div className="form-group mb-4 col-4">
                <label htmlFor="exampleInputEmail1">Enter start date</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  aria-describedby="dateHelp"
                  placeholder="Enter start date"
                  onChange={(e) => this.setState({ fromDate: e.target.value })}
                />
              </div>
              <div className="form-group mb-4 col-4">
                <label htmlFor="exampleInputEmail1">Enter end date</label>

                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  aria-describedby="dateHelp"
                  placeholder="Enter end date"
                  onChange={(e) => this.setState({ toDate: e.target.value })}
                />
              </div>
              <div className=" dropdown mb-4 col-4">
                <label htmlFor="exampleInputEmail1">Select country</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  aria-label="Default select example"
                  onChange={(e: any) => {
                    console.log('country', e.target.value);
                    this.getCity(e.target.value);
                  }}
                >
                  <option value={''}>Select country</option>
                  {getCountry().map((e: string, i: any) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </div>
              {this.state.country && (
                <div className="dropdown mb-4 col-4">
                  <label htmlFor="exampleInputEmail1">Select City</label>
                  <select
                    aria-label="Default select example"
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={(e: any) => {
                      console.log('city', e.target.value);
                      this.setState({ city: e.target.value });
                    }}
                  >
                    <option>Select City</option>
                    {this.state.cityList.map((e: any, i: any) => (
                      <option
                        key={i}
                        value={e.cityName}
                        selected={e.cityName == this.state.city ? true : false}
                      >
                        {e.cityName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <Button
              variant="primary"
              className="shadow-2 mb-4"
              onClick={() => this.doFilter()}
            >
              Filter
            </Button>
          </div>
          {/* <Card.Header>
            <Row>
              <Col> Add New Category</Col>
              <Col className="d-flex justify-content-end"></Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <p>jdfnjnf</p>
          </Card.Body> */}
          <Card className="col-4">
            <Card.Body>Total Tap Count {dashboardData?.tapCount}</Card.Body>
          </Card>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  dashboardData: state.Dashboard,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadData: (data: any) => dispatch(fetchTapCountData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);
