import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavConstant } from '../../../../../store/actionTypes/nav.types.d';

interface IStateProps {
  collapseMenu: boolean;
  windowWidth: number;
  children: React.ReactNode;
}

interface IDispatchProps {
  onToggleNavigation: () => void;
}

class OuterClick extends React.Component<IStateProps & IDispatchProps> {
  wrapperRef: any;

  constructor(props: any) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleOuterClick = this.handleOuterClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOuterClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOuterClick);
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  /**
   * close menu if clicked on Outer of element
   */
  handleOuterClick(event: { target: any }) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.props.windowWidth < 992 && this.props.collapseMenu) {
        this.props.onToggleNavigation();
      }
    }
  }

  render() {
    return (
      <div className="nav-outside" ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    collapseMenu: state.NavReducer.collapseMenu,
    windowWidth: document.body.clientWidth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onToggleNavigation: () => dispatch({ type: NavConstant.COLLAPSEM }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OuterClick);
