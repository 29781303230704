/* eslint-disable import/no-anonymous-default-export */
export default {
  items: [
    {
      id: 'home',
      title: 'Home',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard',
          icon: 'feather icon-home',
        },
        {
          id: 'analytics',
          title: 'Analytics',
          type: 'collapse',
          icon: 'feather icon-bar-chart-2',
          children: [
            {
              id: 'user',
              title: 'User',
              type: 'item',
              url: '/dashboard/analytics/users',
              icon: 'feather icon-users',
            },
            {
              id: 'tap',
              title: 'Tap',
              type: 'item',
              url: '/dashboard/analytics/tap',
              icon: 'feather icon-activity',
            },
          ],
        },
        {
          id: 'nfcStock',
          title: 'NFC Activation',
          type: 'collapse',
          icon: 'feather icon-share-2',
          children: [
            {
              id: 'nfcCreate',
              title: 'Create',
              type: 'item',
              url: '/dashboard/nfcstock/create',
              icon: 'feather icon-file-plus',
            },
            {
              id: 'nfclist',
              title: 'List',
              type: 'item',
              url: '/dashboard/nfcstock/list',
              icon: 'feather icon-activity',
            },
          ],
        },
        {
          id: 'orders',
          title: 'Orders',
          type: 'collapse',
          icon: 'feather icon-share-2',
          children: [
            {
              id: 'orderList',
              title: 'Orders List',
              type: 'item',
              url: '/dashboard/orders/list',
              icon: 'feather icon-file-plus',
            },
          ],
        },
      ],
    },
    {
      id: 'features',
      title: 'Fetaures',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'post',
          title: 'Post',
          type: 'collapse',
          icon: 'feather icon-file',
          children: [
            {
              id: 'list-post',
              title: 'All Posts',
              type: 'item',
              icon: 'feather icon-grid',
              url: '/dashboard/post/list',
            },
            {
              id: 'add-post',
              title: 'New Post',
              type: 'item',
              icon: 'feather icon-tablet',
              url: '/dashboard/post/add',
            },
            {
              id: 'Categories',
              title: 'Categories',
              type: 'item',
              icon: 'feather icon-package',
              url: '/dashboard/post/categories',
            },
          ],
        },
        {
          id: 'products',
          title: 'Products',
          type: 'collapse',
          icon: 'feather icon-file',
          children: [
            {
              id: 'list-product',
              title: 'All Products',
              type: 'item',
              icon: 'feather icon-grid',

              url: '/dashboard/product/list',
            },
            {
              id: 'add-product',
              title: 'New Product',
              type: 'item',
              icon: 'feather icon-codepen',
              url: '/dashboard/product/add',
            },
            {
              id: 'categories',
              title: 'Categories',
              type: 'item',
              icon: 'feather icon-package',
              url: '/dashboard/product/categories',
            },
          ],
        },
        {
          id: 'gallery',
          title: 'Gallery',
          type: 'item',
          icon: 'feather icon-film',
          url: '/dashboard/gallery',
        },
      ],
    },
  ],
};
