import { DashboardConstant } from '../actionTypes/dashboard.types.d';
import { IDashboardAction } from '../models/actions/Dashboard.model';

export const initialState = {
  pending: false,
  success: false,
  users: [],
  tapCount: 0,
  userAlanytics: [],
  message: {},
  errors: {},
};

export const DashboardReducer = (
  state = initialState,
  action: IDashboardAction
) => {
  //   console.log('action', action);

  switch (action.type) {
    case DashboardConstant.FETCH_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        users: action.dashboardData,
      };
    case DashboardConstant.FETCH_TAP_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        tapCount: action.tapCount.count,
      };
    case DashboardConstant.FETCH_USER_ANALYTICS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        userAlanytics: action.userAlanytics,
      };
    case DashboardConstant.FETCH_ERROR:
      return {
        ...state,
        pending: false,
        success: true,
      };
    case DashboardConstant.FETCH:
      return {
        ...state,
        pending: true,
        success: false,
      };
    default:
      return state;
  }
};
