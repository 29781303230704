export enum ProductConstant {
  PRODUCT_SUCCESS = 'NEW_PRODUCT_SUCCESS',
  PRODUCT_ERROR = 'NEW_PRODUCT_ERROR',
  PRODUCT_REQUEST = 'NEW_PRODUCT_REQUEST',
  PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST',
  PRODUCT_FETCH_SUCCEED = 'PRODUCT_FETCH_SUCCEED',
  PRODUCT_FETCH_ERROR = 'PRODUCT_FETCH_ERROR',
  PRODUCT_UPDATE_REQUEST = 'UPDATE_PRODUCT_REQUEST',
  PRODUCT_UPDATE_SUCCEED = 'PRODUCT_UPDATE_SUCCEED',
  PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR',
}
