import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import Home from '../pages/dashboard/home';
import AddNewPost from '../pages/dashboard/post/AddNewPost';
import EditPost from '../pages/dashboard/post/EditPost';
import PostList from '../pages/dashboard/post/PostList';
import CategoryList from '../pages/dashboard/post/category/categorylist';
import ProductList from '../pages/dashboard/products/ProductList';
import ProductCategoryList from '../pages/dashboard/products/category/ProductCategoryList';
import EditProduct from '../pages/dashboard/products/EditProduct';
import AddNewProduct from '../pages/dashboard/products/AddNewProduct';
import Users from '../pages/dashboard/analytics/users';
import Tap from '../pages/dashboard/analytics/tap';
import UsersDetails from '../pages/dashboard/analytics/usersDetails';
import UsersDetailsEdit from '../pages/dashboard/analytics/usersDetailsEdit';

import NfcCreate from '../pages/dashboard/nfcstock/create';
import NfcList from '../pages/dashboard/nfcstock/list';
import EditNfc from '../pages/dashboard/nfcstock/EditNfc';

import OrderList from '../pages/dashboard/orders/list';
import OrderDetails from '../pages/dashboard/orders/OrderDetails';

class AuthRouting extends React.Component<{ AuthenticationReducer?: any }> {
  render() {
    return (
      <>
        <Route exact path="/dashboard">
          <Home />
        </Route>
        <Route
          exact
          path="/dashboard/userDetails/:userId"
          render={(props) => <UsersDetails {...props} />}
        />
        <Route
          exact
          path="/dashboard/userDetails/:userId/edit"
          render={(props) => <UsersDetailsEdit {...props} />}
        />
        <Route exact path="/dashboard/analytics/users">
          <Users />
        </Route>
        <Route exact path="/dashboard/orders/list">
          <OrderList />
        </Route>
        <Route
          exact
          path="/dashboard/orders/:orderId/edit"
          render={(props) => <OrderDetails {...props} />}
        />
        <Route exact path="/dashboard/analytics/tap">
          <Tap />
        </Route>

        <Route exact path="/dashboard/nfcstock/create">
          <NfcCreate />
        </Route>
        <Route exact path="/dashboard/nfcstock/list">
          <NfcList />
        </Route>
        <Route exact path="/dashboard/nfcstock/edit/:id" component={EditNfc} />

        <Route exact path="/dashboard/post/list">
          <PostList />
        </Route>
        <Route exact path="/dashboard/post/categories">
          <CategoryList />
        </Route>
        <Route exact path="/dashboard/product/list">
          <ProductList />
        </Route>
        <Route exact path="/dashboard/product/categories">
          <ProductCategoryList />
        </Route>
        <Route exact path="/dashboard/post/add">
          <AddNewPost />
        </Route>
        <Route exact path="/dashboard/product/add">
          <AddNewProduct />
        </Route>
        <Route exact path="/dashboard/post/edit/:id" component={EditPost} />
        <Route
          exact
          path="/dashboard/product/edit/:id"
          component={EditProduct}
        />
      </>
    );
  }
}

export default connect((state) => state)(AuthRouting);
