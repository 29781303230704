import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';

export function fetchNFCService() {
  return API.get(PathApi.getNfcList)
    .then((data: any) => {
      console.log('PathApi.getNfcList', data);

      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function deleteService(payload: any = {}) {
  console.log('fetchTapsService', payload);

  return API.post(PathApi.getnfcTapCountFilter, payload.payload)
    .then((data: any) => {
      console.log('data', data);
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function uploadService(payload: any = {}) {
  console.log('payload', payload);

  return API.post(PathApi.usersByFilterDate, payload.payload)
    .then((data: any) => {
      console.log('data', data);
      return data;
    })
    .catch((error: any) => {
      console.log(error);

      return error.response;
    });
}
