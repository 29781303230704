import React, { Component } from 'react';
import _ from 'lodash';

type props = {
  input: any;
  itemValue: any;
  id: string;
  meta:any;
};
export default class ArrayCheckbox extends Component<props> {
  onChange(event: any) {
    const { input, itemValue } = this.props;

    var oldValues = input.value || [];

    var newValues = _.without(oldValues, itemValue); // remove value

    if (event.target.checked) {
      // was checked
      newValues = oldValues.concat(itemValue);
    }
    input.onChange(newValues);
  }

  render() {
    const { input, itemValue, id } = this.props;
    console.log(input.value);
    console.log(itemValue);
    return (
      <input
        {...input}
        type="checkbox"
        id={id}
        // defaultChecked={true}
        checked={input.value.indexOf(itemValue) >= 0}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}
