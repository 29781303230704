import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

interface IProps {
  categories: [];
  className?: string;
  input?: any;
}

const CategoryInputSelect: React.FC<IProps> = (props) => {
  const { input } = props;

  function getCategories(item?: any) {
    let catArr = [];
    catArr.push({ value: item.id, label: item.title });
    if (item.hasOwnProperty('childerns')) {
      item.childerns.map((child: any) => {
        catArr.push(...getCategories(child));
      });
    }
    return catArr;
  }

  function opts() {
    let option = [];
    option.push({ label: 'All', value: '0' });
    props.categories && props.categories.map((item: any) => {
      option.push(...getCategories(item));
    });
    return option;
  }

  const handleBlur = () => {
    setTimeout(() => {
      const { input } = props;
      input.onBlur(input.value);
    }, 1);
  };

  return (
    <Select
      {...input}
      options={opts()}
      onChange={(value) => input.onChange(value)}
      className={handleBlur}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};


const mapStateToProps = (state: any,ownProps: any) => ({
  categories: ownProps.categories,
});

export default connect(mapStateToProps)(CategoryInputSelect);
