import { DashboardConstant } from '../actionTypes/dashboard.types.d';

export interface IDashboardAction {
  pending?: boolean;
  success?: boolean;
  message?: any;
  type: DashboardConstant;
  dashboardData?: [];
  tapCount?: [];
  userAlanytics?: [];
  payload?: any;
}

export const loadDashboardData = (payload: any): IDashboardAction => ({
  type: DashboardConstant.FETCH_SUCCESS,
  dashboardData: payload,
});

export const fetchDashboardData = (): IDashboardAction => ({
  type: DashboardConstant.FETCH,
});

export const fetchTapCountData = (payload: any): IDashboardAction => ({
  type: DashboardConstant.FETCH_TAP_COUNT,
  payload,
});
export const loadTapCountData = (payload: any): IDashboardAction => {
  console.log('tapppp', payload);

  return {
    type: DashboardConstant.FETCH_TAP_SUCCESS,
    tapCount: payload,
  };
};

export const fetchUserAnalyticsData = (payload: any): IDashboardAction => ({
  type: DashboardConstant.FETCH_USER_ANALYTICS,
  payload,
});
export const loadUserAnalyticsData = (payload: any): IDashboardAction => ({
  type: DashboardConstant.FETCH_USER_ANALYTICS_SUCCESS,
  userAlanytics: payload,
});
