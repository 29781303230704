import {
  cancel,
  takeLatest,
  take,
  call,
  put,
  delay,
  cancelled,
} from 'redux-saga/effects';

import { push } from 'connected-react-router';
import { Error, Clear } from '../actions/alert.action';
import loginApi from '../../services/login.service';
import {
  Authenticated,
  UnAuthenticated,
} from '../actions/authentication.action';
import { ILoginResponseState } from '../models/api/Login.api.model';
import { LoginConstant } from '../actionTypes/login.types.d';
import { loginFailed, onLoginResponse } from '../actions/login.action';
import { fetchPostCategoryRequest } from '../actions/post-category.action';
import { toast } from 'react-toastify';
import { fetchFilesRequest } from '../actions/file-manager.action';
import { fetchPostRequest } from '../actions/post.action';
import { initialState } from '../reducers/Post.reducer';
import { fetchProductCategoryRequest } from '../actions/product-category.action';
import { fetchProductRequest } from '../actions/product.action';

export function* logout() {
  yield put(UnAuthenticated());
  localStorage.removeItem('token');
  localStorage.removeItem('tycState');
  console.log(localStorage['tycState']);
  yield put(push('/login'));
}

function* loginProcess(action: any) {
  try {
    console.log(action);
    const response: ILoginResponseState = yield call(loginApi, action.request);
    console.log(response);
    if (response.status === 200) {
      const loginInfo: any = response.data;
      console.log('loginInfo', loginInfo);

      yield put(Authenticated(loginInfo));
      yield put(onLoginResponse({ message: loginInfo.message }));
      localStorage.setItem('token', loginInfo.token);
      yield put(fetchFilesRequest());
      yield delay(1000);
      yield put(fetchPostCategoryRequest());
      yield delay(1000);
      yield put(fetchPostRequest({ filter: initialState.filter }));
      yield delay(1000);
      yield put(fetchProductCategoryRequest());
      yield delay(1000);
      yield put(fetchProductRequest({ filter: initialState.filter }));
      yield put(push('/dashboard'));
      let message = 'welcome to TYC Admin';
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    console.log(error);
    // yield put(
    //   stopSubmit('login', { email: 'Login Failed', _error: 'Login failed' })
    // );
    if (!error) {
      // yield put(reset('login'));
    }

    let err = { message: 'Validation failed' };
    if (error.message && error.message !== 'Network Error') {
      if (error.statusCode != 401) {
        error.details.map((item: any) => {
          if (item.path === '/password') {
            err = Object.assign(err, { password: item.message });
          }
          if (item.path === '/email') {
            err = Object.assign(err, { username: item.message });
          }
        });
      } else {
        err.message = error.message;
      }
    } else {
      err.message = 'Something went wrong. try again later!!';
    }
    yield put(
      loginFailed({ response: { error: err, message: error.message } })
    );
    yield put(Error({ message: err.message }));
  }
}

export function* watchLoginAsync(): any {
  yield takeLatest(LoginConstant.LOGIN_REQUEST, loginProcess);
  yield takeLatest(LoginConstant.LOG_OUT, logout);
}
