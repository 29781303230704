import { put, cancelled, takeLatest, take, call } from 'redux-saga/effects';
import {
  fetchFilesRequestService,
  submitMediaService,
} from '../../services/file-manager.service';
import {
  fetchFilesRequest,
  fetchFilesSuccess,
} from '../actions/file-manager.action';
import { FileUploadConstant } from '../actionTypes/fileManager.types.d';
import { IFetchMediaResponse } from '../models/api/FileManger.api.model';
import { errorToast, successToast } from '../../lib/toasthelper';
import { b64toBlob } from '../../lib/blobhelper';

function* fetchfilesApi() {
  try {
    const response: IFetchMediaResponse = yield call(fetchFilesRequestService);
    console.log(response);
    if (response.status === 200) {
      const files: any = response.data;
      yield put(fetchFilesSuccess(files));
      successToast('Files Fetched');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    errorToast(error.message);
  }
}

function* submitUploadMedia(files: any) {
  try {
    let filesInfo: IFetchMediaResponse = yield call(
      submitMediaService,
      files.files
    );
    successToast('File Uploaded Successfully');
  } catch (error: any) {
    errorToast(error.message);
  } finally {
  }
}
export function* fetchfilesWatcher() {
  console.log('files');
  yield takeLatest(FileUploadConstant.FETCH_FILES_REQUEST, fetchfilesApi);
  yield takeLatest(FileUploadConstant.FILE_UPLOAD_REQUEST, submitUploadMedia);
}

/* function b64toBlob(dataURI:string) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}
const filesApi = (files:any)=>{

    let formData = new FormData;
    formData.append('media-upload', b64toBlob(files.files), files.fileMeta.fileName);
    let sleep =(ms:number)=> new Promise(resolve =>setTimeout(resolve,ms));
    return sleep(1000).then(()=>{
        return new Promise(function(resolve, rej){
            return API.post(PathApi.fileUpload,formData,"multipart/form-data")
           .then((res)=>{
                resolve(res);
           }).catch((err)=>{
               return rej(err);
           });
        })

    });
}; */

/* function* filesProcess(files:any){
    let filesInfo;
    try {

        yield put(startSubmit('filesAdd'));

        filesInfo = yield call(filesApi, files);

        yield put(Success({type:Success, message:filesInfo.message}));

        yield call(fetchfilesWatcher);

        yield put(stopSubmit('filesAdd'));

     } catch (error: any) {
        yield put(stopSubmit('filesAdd', error));
        yield put(Error(error.message))
        toast.error(error.message,{position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
        });

        // yield call(reject, new SubmissionError({ username: 'Username doesn\'t exist', password: 'Please enter your password' }));

        // throw new SubmissionError({_error:error});
    } finally{
        if(yield cancelled()){
            //yield put(push('/files'));
        };
    }
    return filesInfo;

}; */
/* export function* filesWatcher(){
    while(true){
        const {files} = yield take(FileUploadConstant.REQUEST);
        const task = yield fork(filesProcess,files);


    }
} */
