import { Container, Row, Col, Form as BForm } from 'react-bootstrap-v5';
import PageTitle from '../../../components/Dashboard/common/PageTitle';
import Editor from '../../../components/Dashboard/post/add-new-post/Editor';
import SidebarCategories from '../../../components/Dashboard/post/add-new-post/SidebarCategories';
import ReactMediaLibraryWrapper from '../../../components/Dashboard/post/add-new-post/SideBarMediaUpload';
import React from 'react';
import { connect } from 'react-redux';
import SidebarActions from '../../../components/Dashboard/post/add-new-post/SidebarActions';
import { isEmpty } from 'lodash';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import slug from 'slug';

interface IProps {
  onSubmit: (values: any) => void;
  handleSubmit: (values: any) => void;
  reset: any;
  pristine: any;
  submitting: boolean;
  valid: any;
  error: any;
  editMode: boolean;
  initialValues: any;
  categories: any;
}

interface IState {
  imgSrc?: string;
}

class PostForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      imgSrc: '',
    };
  }

  onMediaChange = (e: any) => {
    this.setState({ imgSrc: e });
    console.log(e);
  };

  componentDidMount() {
    if (!isEmpty(this.props.initialValues.post)) {
      this.setState({ imgSrc: this.props.initialValues.post.bannerImage });
    }
  }

  calculateSlug = createDecorator({
    field: 'post.title',
    updates: {
      'post.slug': (titleValue, allValues) =>
        isEmpty(titleValue) ? '' : slug(titleValue, '-'),
    },
  });

  onSubmit = (values: any) => {
    this.props.onSubmit(values);
  };

  render() {
    const { onSubmit, submitting, valid, editMode, initialValues, categories } =
      this.props;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Form
          onSubmit={this.onSubmit}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          decorators={[this.calculateSlug]}
          className="align-self-center"
          initialValues={initialValues}
        >
          {({ form, handleSubmit, invalid, values }) => (
            <BForm onSubmit={handleSubmit}>
              {/* Page Header */}
              <Row noGutters className="page-header py-4">
                <PageTitle
                  sm="4"
                  title={editMode ? 'Edit' : 'Add New'}
                  subtitle="Post"
                  className="text-sm-left"
                />
              </Row>
              <Row>
                {/* Editor */}
                <Col lg="9" md="12">
                  <Editor type={'post'} />
                </Col>

                {/* Sidebar Widgets */}
                <Col lg="3" md="12">
                  <SidebarActions
                    title={'Actions'}
                    submitting={submitting}
                    editMode={editMode}
                    form={form}
                    type={'post'}
                  />
                  <SidebarCategories
                    categories={categories}
                    currentCategories={initialValues.postCategories}
                    type={`postCategories`}
                  />
                  <ReactMediaLibraryWrapper
                    imgSrc={this.state.imgSrc}
                    onChange={this.onMediaChange}
                    title="Banner Image"
                    type="post"
                    form={form}
                  />
                </Col>
              </Row>
            </BForm>
          )}
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  alertStore: state.AlertReducer, // some data from the server
  editMode: ownProps.editMode,
  initialValues: ownProps.initialValues,
  categories: state.PostCategories.categoryList.categories,
});

export default connect(mapStateToProps)(PostForm);
