/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  textFilter,
  numberFilter,
  dateFilter,
  selectFilter,
} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { useEffect } from 'react';

const PTag = ({ text, size = 12, color = 'black' }: any) => {
  return (
    <p
      style={{
        fontSize: size,
        alignSelf: 'center',
        marginTop: 5,
        marginBottom: 5,
        wordWrap: 'break-word',
        color,
      }}
    >
      {text}
    </p>
  );
};
const CaptionElement = ({ title }: any) => (
  <h3
    style={{
      borderRadius: '0.25em',
      textAlign: 'center',
      color: '#000',
      border: '1px solid gray',
      padding: '0.5em',
      wordWrap: 'break-word',
    }}
  >
    {title}
  </h3>
);
interface DataTableListIF {
  title?: any;
  data?: any;
  columns?: any;
  isSelect?: boolean;
  setIdList?: any;
  loading?: boolean;
  onTableChangeData?: (page: number, sizePerPage: number) => void;
  paginationData?: any;
  exportCSV?: boolean;
}

const DataTableList = ({
  title,
  data,
  columns,
  isSelect = false,
  setIdList,
  loading = false,
  exportCSV = false,
  onTableChangeData,
  paginationData = {
    page: 0,
    totalSize: 0,
    sizePerPage: 10,
  },
}: DataTableListIF) => {
  const { ExportCSVButton } = CSVExport;
  useEffect(() => {
    cheangeClass();
  }, []);

  const cheangeClass = () => {
    var y = document.getElementsByClassName('react-bootstrap-table');
    y[0].classList.remove('react-bootstrap-table');
  };
  function priceFormatter(
    column: any,
    colIndex: any,
    { sortElement, filterElement }: any
  ) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p style={{ fontSize: 10 }}>{filterElement}</p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ fontSize: 11 }}>{column.text}</p>
          <p style={{ fontSize: 11 }}>{sortElement}</p>
        </div>
      </div>
    );
  }

  const cols = columns.map((e: any) => ({
    ...e,
    headerFormatter: priceFormatter,
    style: {
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
    },
  }));

  const selectRow: any = {
    mode: 'checkbox',
    onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
      // console.log(row, isSelect);
      setIdList(row, isSelect, 's');
    },
    onSelectAll: (isSelect: any, rows: any, e: any) => {
      // console.log(rows);
      setIdList(rows, isSelect, 'm');
    },
  };

  const onTableChange = (type: any, { sortField, sortOrder, data }: any) => {
    console.log(type, sortField, sortOrder, data);
  };
  return (
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={cols}
      exportCSV={exportCSV}
    >
      {(props) => (
        <div>
          {exportCSV && (
            <button>
              <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton>
            </button>
          )}
          <hr />
          <BootstrapTable
            caption={title ? <CaptionElement title={title} /> : ''}
            filter={filterFactory()}
            remote={
              onTableChangeData
                ? { pagination: true, filter: false, sort: false }
                : undefined
            }
            {...props.baseProps}
            onTableChange={onTableChange}
            pagination={paginationFactory(
              onTableChangeData
                ? {
                    onPageChange: (page: number, sizePerPage: number) =>
                      onTableChangeData && onTableChangeData(page, sizePerPage),
                    onSizePerPageChange: (page: number, sizePerPage: number) =>
                      onTableChangeData && onTableChangeData(0, page),
                    showTotal: true,
                    ...paginationData,
                  }
                : { sizePerPage: 10 }
            )}
            selectRow={isSelect ? selectRow : undefined}
            noDataIndication="Table is Empty"
            bootstrap4
            striped
            hover
            condensed
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export {
  DataTableList,
  PTag,
  textFilter,
  numberFilter,
  dateFilter,
  CaptionElement,
  selectFilter,
};
