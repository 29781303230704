/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Row, Col, Image, Table, Container } from 'react-bootstrap';
import { PathApi } from '../../../../config/api.path.config';
import { API } from '../../../../config/api.config';
import { successToast, warningToast } from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';

class Index extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      userData: null,
      directApp: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    console.log('***', this.props.match.params);
    this.getUserData();
  }

  getUserData = () => {
    API.get(PathApi.usersByid + this.props.match.params.userId)
      .then((data: any) => {
        console.log('data', data);
        let userData = data.data;
        let directApp = null;
        if (userData?.isDirect) {
          directApp = userData[
            userData.profileTabType == 'Personal'
              ? 'socialProfiles'
              : 'businessProfiles'
          ].filter((e: any) => e.id == userData.activeDirect)[0];
        }
        // successToast('User data loaded');
        this.setState({ userData, isLoading: false, directApp });
      })
      .catch((error: any) => {
        // console.log(error);
        this.setState({ userData: null, isLoading: true });
        warningToast('Error in loading user data NFC data');
      });
  };

  render() {
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    const { userData, directApp } = this.state;
    return (
      <Container>
        <Col md="auto">
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: 20, marginBottom: 10 }}
          >
            {/* {userData?.profilePic && ( */}
            <Image
              src={userData.profilePic}
              thumbnail
              style={{ width: 200, height: 200 }}
            />
            {/* )} */}
          </Row>
          {/* </Col> */}
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Name:- </span>
              {userData?.name}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>UserName:- </span>
              {userData?.username}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Profile Link:- </span>
              <a
                href={`https://tapyourchip.com/${userData.id}`}
                target="_blank"
              >{`https://tapyourchip.com/${userData.id}`}</a>
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Email:- </span>
              {userData?.email}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Country Code:- </span>
              {userData?.countryCode}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Calling Code:- </span>
              {userData?.callingCode}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Mobile:- </span>
              {userData?.mobile}
            </p>
          </Row>

          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Account Created:- </span>
              {userData?.created}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Address:- </span>
              {userData?.address}
            </p>
          </Row>
          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>User Bio:- </span>
              {userData?.userBio}
            </p>
          </Row>

          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Account Type:- </span>
              {userData?.accountType ?? 'both'}
            </p>
          </Row>

          <Row className="justify-content-md-center">
            <p style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>Direct ON/OFF:- </span>
              {userData?.isDirect ? 'YES' : 'NO'}
            </p>
          </Row>

          {userData?.isDirect && (
            <Row className="justify-content-md-center">
              <p style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Direct App:- </span>
                {directApp.name}
              </p>
              <p style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>Direct app Link:- </span>
                <a href={directApp.uri}>{directApp.uri}</a>
              </p>
            </Row>
          )}
        </Col>

        <Row className="md" style={{ marginTop: 30 }}>
          <Col xs={6}>
            <h5>Top Social Profiles</h5>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Sl No
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    App Name
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Tap Count
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Uri
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData['socialProfiles'] &&
                  userData['socialProfiles']
                    .slice(0, 3)
                    .map((e: any, i: any) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{e.name}</td>
                        <td>{e?.count ?? 0}</td>
                        <td>
                          <a href={e.uri} target="_blank">
                            {e.uri}
                          </a>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </Col>
          <Col xs={6}>
            <h5>Top Business Profiles</h5>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Sl No
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    App Name
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Tap Count
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Uri
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData['businessProfiles'] &&
                  userData['businessProfiles']
                    .slice(0, 3)
                    .map((e: any, i: any) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{e.name}</td>
                        <td>{e?.count ?? 0}</td>
                        <td>
                          <a href={e.uri} target="_blank">
                            {e.uri}
                          </a>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row style={{ height: 200 }}></Row>
      </Container>
    );
  }
}
export default Index;
