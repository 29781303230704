import React from 'react';
import {
  Row,
  Col,
  ListGroupItem,
  Button,
  Form as BForm,
  Card,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import slug from 'slug';
import { Form, Field } from 'react-final-form';

import createDecorator from 'final-form-calculate';
import { isEmpty } from 'lodash';
import CategoryInputSelect from './CategoryInputSelect';
import { FieldInput } from '../../../lib/formhelper';
import SideBarMediaUpload from '../post/add-new-post/SideBarMediaUpload';
import { required } from '../../../lib/validtionhelper';

interface IProps {
  onSubmit: (param: any) => void;
  pristine: any;
  submitting: boolean;
  valid: any;
  error: any;
  change: (p: string, s: string) => void;
  editMode?: boolean;
  category?: any;
  type?: string;
  initialValues?: any;
  reset: () => void;
}

interface ICFState {
  imgSrc?: string;
}

class CategoryForm extends React.Component<IProps, ICFState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ...this.state,
      imgSrc: '',
    };
  }

  calculateSlug = createDecorator({
    field: 'category.title',
    updates: {
      'category.slug': (titleValue, allValues) =>
        isEmpty(titleValue) ? '' : slug(titleValue, '-'),
    },
  });

  // componentDidMount() {
  //   console.log('this.props.initialValues', this.props.initialValues);
  //   if (!isEmpty(this.props.initialValues.post)) {
  //     this.setState({ imgSrc: this.props.initialValues.post.image });
  //   }
  //   if (!isEmpty(this.props.initialValues.category)) {
  //     this.setState({ imgSrc: this.props.initialValues.category.image });
  //   }
  // }

  onMediaChange = (e: any) => {
    this.setState({ imgSrc: e });
  };
  unsetMedia = () => {
    this.setState({ imgSrc: '' });
  };
  doSubmit = (values: any) => {
    this.props.onSubmit(values);
    this.unsetMedia();
  };

  shouldComponentUpdate(nextProps: any, nextState: any) {
    console.log('nextProps', nextProps.initialValues);

    if (
      JSON.stringify(nextProps.initialValues) !==
      JSON.stringify(this.props.initialValues)
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { onSubmit, submitting, editMode, category, initialValues } =
      this.props;
    return (
      <Form
        onSubmit={this.doSubmit}
        className="align-self-center"
        initialValues={initialValues}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        decorators={[this.calculateSlug]}
      >
        {({ form, handleSubmit, invalid }) => (
          <BForm onSubmit={handleSubmit}>
            <Card.Header>
              <Row>
                <Col> Add New Category</Col>
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      form.reset();
                      this.props.reset();
                      this.unsetMedia();
                    }}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Field name="category.categoryTypeId">
                  {({ input }) => (
                    <div>
                      <div className="field-wrapper">
                        <input
                          {...input}
                          type="hidden"
                          name="category.categoryTypeId"
                        />
                      </div>
                    </div>
                  )}
                </Field>

                <Col>
                  <Row>
                    <label>Title</label>
                    <Field
                      id="title-en"
                      type="text"
                      className="input-group mb-4"
                      placeholder="new-post"
                      validate={required}
                      component={FieldInput}
                      name={'category.title'}
                    />
                  </Row>
                  <Row>
                    <label>Slug</label>
                    <Field
                      type="text"
                      size="lg"
                      className="input-group mb-4"
                      placeholder="new-post"
                      // readOnly={true}
                      validate={required}
                      component={FieldInput}
                      name="category.slug"
                    />
                  </Row>

                  <Row>
                    <label>select Parent</label>
                    <Field
                      name="category.parentId"
                      categories={category}
                      component={CategoryInputSelect}
                      initialValue={`0`}
                    />
                  </Row>
                  <Row>
                    <SideBarMediaUpload
                      imgSrc={this.state.imgSrc || initialValues.category.image}
                      onChange={this.onMediaChange}
                      title="Category Image"
                      form={form}
                      type="category"
                    />
                  </Row>
                </Col>
              </Row>
              <ListGroupItem className="d-flex border-0">
                {(editMode && (
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={submitting}
                    className="shadow-2 mb-4"
                  >
                    {submitting ? (
                      <span>
                        <FaSpinner />
                        Updating
                      </span>
                    ) : (
                      <span>
                        <i className="material-icons">file_copy</i> Update
                      </span>
                    )}
                  </Button>
                )) || (
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={submitting}
                    className="shadow-2 mb-4"
                  >
                    {submitting ? (
                      <span>
                        <FaSpinner />
                        wait
                      </span>
                    ) : (
                      <span>
                        <i className="material-icons">file_copy</i> Submit
                      </span>
                    )}
                  </Button>
                )}
              </ListGroupItem>
              {editMode && (
                <Field
                  type="hidden"
                  name="category.id"
                  component="input"
                  value={category.id}
                />
              )}
            </Card.Body>
          </BForm>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  editMode: ownProps.editMode,
  category: ownProps.category,
  initialValues: ownProps.initialValues,
  reset: ownProps.reset,
});
const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
