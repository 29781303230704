import { NFCStockConstant } from '../actionTypes/NFCStock.types.d';

export interface INFCStockAction {
  pending?: boolean;
  success?: boolean;
  message?: any;
  type: NFCStockConstant;
  payload?: any;
  nfclist?: any;
}

export const fetchNFCData = (): INFCStockAction => ({
  type: NFCStockConstant.FETCH_NFC_DATA,
});

export const loadNFCData = (payload: any): INFCStockAction => ({
  type: NFCStockConstant.FETCH_NFC_DATA_SUCCESS,
  nfclist: payload,
});
