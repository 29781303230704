/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Card, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { render } from '@testing-library/react';
import { FaSpinner } from 'react-icons/fa';
import { FormApi } from 'final-form';
import { Field, FieldRenderProps } from 'react-final-form';
import { FieldInput } from '../../../../lib/formhelper';

interface props {
  title: string;
  submitting: boolean;
  editMode: boolean;
  form:FormApi;
  type:string;
}

class SidebarActions extends React.Component<props, FormApi> {
  render() {
    const { form, submitting, title, editMode } = this.props;
    return (
      <Card className="mb-3">
        <Card.Header className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </Card.Header>

        <Card.Body className="p-0">
          <Field
            name={`${this.props.type}.published`}
            type="hidden"
            component={FieldInput}
            value="false"
          />
          <ListGroup>
            <ListGroupItem className="p-3">
              <span className="d-flex mb-2">
                <i className="material-icons mr-1">flag</i>
                <strong className="mr-1">Status:</strong> Draft{' '}
                <a className="ml-auto" href="#">
                  Edit
                </a>
              </span>
              <span className="d-flex mb-2">
                <i className="material-icons mr-1">visibility</i>
                <strong className="mr-1">Visibility:</strong>{' '}
                <strong className="text-success">Public</strong>{' '}
                <a className="ml-auto" href="#">
                  Edit
                </a>
              </span>
              <span className="d-flex mb-2">
                <i className="material-icons mr-1">calendar_today</i>
                <strong className="mr-1">Schedule:</strong> Now{' '}
                <a className="ml-auto" href="#">
                  Edit
                </a>
              </span>
              <span className="d-flex">
                <i className="material-icons mr-1">score</i>
                <strong className="mr-1">Readability:</strong>{' '}
                <strong className="text-warning">Ok</strong>
              </span>
            </ListGroupItem>
            <ListGroupItem className="d-flex border-0">
              {(editMode && (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={submitting}
                  className="shadow-2 mb-4"
                >
                  {submitting ? (
                    <span>
                      <FaSpinner />
                      Updating
                    </span>
                  ) : (
                    <span>
                      <i className="material-icons">file_copy</i> Update
                    </span>
                  )}
                </Button>
              )) || (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={submitting}
                  className="shadow-2 mb-4"
                  onClick={()=>form.change(`${this.props.type}.published`, true)}
                >
                  {submitting ? (
                    <span>
                      <i className="fa fa-spin fa-spinner">wait</i>
                    </span>
                  ) : (
                    <span>
                      <i className="material-icons">file_copy</i> Publish
                    </span>
                  )}
                </Button>
              )}
            </ListGroupItem>
          </ListGroup>
        </Card.Body>
      </Card>
    );
  }
}

export default SidebarActions;
