import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';

export function fetchPostCategoriesService() {
  return API.get(PathApi.postCategories)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}
export function submitPostCategoryService(category:object){
    return API.post(PathApi.categories,category)
      .then((data)=>{
        return data;
      }).catch((err)=>{
        return err.response;
      });

};

export function UpdatePostCategoryService(category:any){
    return API.patch(PathApi.category+`/${category.category.id}`,category)
      .then((res)=>{
        return res;
      }).catch((err)=>{
        return err;
      });
};
