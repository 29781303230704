import {
  take,
  fork,
  call,
  put,
  cancelled,
  takeLatest,
} from 'redux-saga/effects';
import { ProductCategoryConstant } from '../actionTypes/product-category.types.d';
import {
  IFetchProductCategoriesResponse,
  IProductCategoryResponse,
} from '../models/api/ProductCategory.api.model';
import {
  fetchProductCategoryRequest,
  fetchProductCategorySuccess,
} from '../actions/product-category.action';
import { fetchProductCategoriesService } from '../../services/product-category.service';
import { submitPostCategoryService } from '../../services/post-category.service';
import { errorToast, successToast } from '../../lib/toasthelper';
import { productError, productSuccess } from '../actions/product.action';
import { PostConstant } from '../actionTypes/post.types.d';

function* submitProductCatSaga(category: any) {
  let categoryInfo: IProductCategoryResponse;
  let categoryData;
  try {
    categoryInfo = category.category.category.id
      ? ''
      : yield call(submitPostCategoryService, category.category.category);
    if (categoryInfo.status === 200) {
      categoryData = categoryInfo.data;
      yield put(productSuccess({ message: categoryData.message }));
      yield put(fetchProductCategoryRequest());
      yield call(
        successToast,
        `${
          category.category.category.id ? 'Updated' : 'Added'
        } Category Successfully`
      );
    } else {
      throw categoryInfo.data.error;
    }
  } catch (error: any) {
    yield put(productError(error.message));
    yield call(errorToast, error.message);
  }
}

function* fetchProductCategoryAPI() {
  try {
    const response: IFetchProductCategoriesResponse = yield call(
      fetchProductCategoriesService
    );
    console.log('Product category', response);
    if (response.status === 200) {
      const categories: any = response.data;
      yield put(fetchProductCategorySuccess(categories[0]));
    } else {
      throw response.data.error;
    }
  } catch (error: any) {}
}

export function* initialProductCategoryWatcher() {
  yield takeLatest(
    ProductCategoryConstant.FETCH_REQUEST,
    fetchProductCategoryAPI
  );
  yield takeLatest(ProductCategoryConstant.REQUEST, submitProductCatSaga);
}
