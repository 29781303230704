import React from 'react';
import { FileLibraryListItem } from 'react-media-library-daniel-daleva';
import { Card, FormCheck } from 'react-bootstrap-v5';

const imgStyle: React.CSSProperties = {
  width: '100%',
  height: '150px',
  objectFit: 'cover',
  objectPosition: '50% 50%',
};

interface IProps extends FileLibraryListItem {
  selectedItem: FileLibraryListItem | undefined;
}

const CustomLibraryCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <Card
      bg={
        props.selectedItem !== undefined && props.selectedItem.id === props.id
          ? 'primary'
          : undefined
      }
    >
      <FormCheck
        type="checkbox"
        label="Check me out"
        className={`checkbox checkbox-fill d-inline`}
        style={{
          padding: '0px !important',
          position: 'absolute',
          right: '-25px',
          top: '-25px',
        }}
      >
        <FormCheck.Input
          id={`checkbox-${props.title}`}
          name={`imageCards`}
          onChange={() => {}}
          type="checkbox"
          checked={
            props.selectedItem !== undefined &&
            props.selectedItem.id === props.id
              ? true
              : false
          }
        />
        <FormCheck.Label
          htmlFor={`checkbox-${props.title}`}
          className="cr"
        ></FormCheck.Label>
      </FormCheck>
      {props.thumbnailUrl && (
        <>
        <Card.Img
          variant="top"
          src={props.thumbnailUrl}
          style={imgStyle}
          srcSet={props.thumbnailUrl}
        />
        <Card.Footer style={{padding:0}}>{props.title}</Card.Footer>
        </>
      )}
    </Card>
  );
};

export default CustomLibraryCard;
