import React from 'react';
import { Card, Row, Col } from 'react-bootstrap-v5';

export const PostTableHeader = () => (
  <Card className="d-none d-sm-block bg-transparent" text="muted" border="light">
    <Card.Header style={{ padding: '.5rem'}}>
      <Row>
        <Col xs={12} md={5}>
          Title
        </Col>
        <Col className="col-md-3">Slug</Col>
        <Col className="col-md-2">Categories</Col>
        <Col className="col-md-2">Actions</Col>
      </Row>
    </Card.Header>
  </Card>
);
