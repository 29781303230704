import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import {
  DataTableList,
  PTag,
  textFilter,
  dateFilter,
  CaptionElement,
} from '../../../../components/DataTable';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
import { fetchNFCData } from '../../../../store/actions/NFCStock.action';
import { PathApi } from '../../../../config/api.path.config';
import { API } from '../../../../config/api.config';
import { successToast, warningToast } from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface IMainProps {
  loadData: () => void;
  loadCategory: () => void;
  NFCStockReducer: any;
}

class Index extends React.Component<IMainProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      selectedIDs: [],
    };
  }

  componentDidMount() {
    this.props.loadData();
  }

  deleteRow = (param: string) => {
    API.delete(PathApi.deleteNfcTagData + param)
      .then((data: any) => {
        this.props.loadData();
        successToast('Deleted Successfully');
        this.setState({ isLoading: false });
        this.setIdList(param, false, 's');
      })
      .catch((error: any) => {
        this.setState({ isLoading: false });
        warningToast('Error in Deleting NFC data');
      });
  };

  setIdList = (ids: any, isSelected: any, type: string = 's') => {
    if (type === 's') {
      let idArr = this.state.selectedIDs;
      if (!isSelected) {
        idArr = this.state.selectedIDs.filter((e: any) => e.id != ids.id);
      } else {
        idArr.push(ids);
      }
      this.setState({ selectedIDs: idArr }, () => {
        console.log('ids s', this.state.selectedIDs);
      });
    } else {
      this.setState({ selectedIDs: isSelected ? ids : [] }, () => {
        console.log('ids m', this.state.selectedIDs);
      });
    }
  };

  deleteAll = () => {
    this.setState({ isLoading: true });

    API.delete(PathApi.stockMultipleDelete, this.state.selectedIDs)
      .then((data: any) => {
        this.props.loadData();
        successToast('Deleted Successfully');
        this.setState({ isLoading: false, selectedIDs: [] });
      })
      .catch((error: any) => {
        this.setState({ isLoading: false });
        warningToast('Error in Deleting NFC data');
      });
  };

  markAsPurchase = () => {
    this.setState({ isLoading: true });

    API.post(PathApi.stockMarkAsPurchased, this.state.selectedIDs)
      .then((data: any) => {
        this.props.loadData();
        successToast('Stock Updated Successfully');
        this.setState({ isLoading: false, selectedIDs: [] });
      })
      .catch((error: any) => {
        this.setState({ isLoading: false });
        warningToast('Error in Updating Stock Data');
      });
  };

  columns: any = [
    {
      text: 'Serial No',
      dataField: 'serialNo',
      sort: true,
      filter: textFilter(),
    },
    {
      text: 'Password',
      dataField: 'password',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag text={row.isActive ? 'Yes' : 'No'} />
      ),
      text: 'Active/Deactive',
      dataField: 'isActive',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={row.inStock ? 'In Stock' : 'Stock Out'}
          color={row.inStock ? 'green' : 'red'}
        />
      ),
      text: 'Stock',
      dataField: 'inStock',
      sort: true,
      filter: textFilter(),
    },
    {
      text: 'Category Type',
      dataField: 'categoryType',
      sort: true,
      filter: textFilter(),
    },
    {
      // formatter: (cell: any, row: any) => <PTag text={row.lastName} />,
      text: 'Product Id',
      dataField: 'productId',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag text={row?.user?.name ?? row?.user?.username} />
      ),
      text: 'User name',
      dataField: 'user',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={
            row?.dateOfArrival
              ? moment(row.dateOfArrival).format('DD/MM/YYYY')
              : null
          }
        />
      ),
      text: 'Date Of Arrival',
      dataField: 'dateOfArrival',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={row?.created ? moment(row.created).format('DD/MM/YYYY') : null}
        />
      ),
      text: 'Created',
      dataField: 'created',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <Col>
          <Link
            to={{
              pathname: `/dashboard/nfcstock/edit/${row.id}`,
              state: { nfcData: row },
            }}
          >
            <Button variant="primary" size="sm">
              View
            </Button>
          </Link>
          <Button
            variant="danger"
            size="sm"
            onClick={() => this.deleteRow(row.id)}
          >
            Delete
          </Button>
        </Col>
      ),
      text: 'Action',
      dataField: 'id',
    },
  ];

  render() {
    const { NFCStockReducer } = this.props;
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    return (
      <Row>
        <Row>
          <CaptionElement title={'NFC STOCK LIST'} />
          <Col>
            <Button
              disabled={
                this.state.isLoading || this.state.selectedIDs.length == 0
                  ? true
                  : false
              }
              variant="danger"
              size="sm"
              onClick={() => this.deleteAll()}
            >
              Delete All
            </Button>
            <Button
              disabled={
                this.state.isLoading || this.state.selectedIDs.length == 0
                  ? true
                  : false
              }
              variant="warning"
              size="sm"
              onClick={() => this.markAsPurchase()}
            >
              Mark As Purchased
            </Button>
          </Col>
          <DataTableList
            data={NFCStockReducer.nfclist}
            columns={this.columns}
            isSelect={true}
            setIdList={(params: any, isSelected: any, type: any) =>
              this.setIdList(params, isSelected, type)
            }
            loading={this.state.isLoading}
          />
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  NFCStockReducer: state.NFCStockReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadData: () => dispatch(fetchNFCData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);
