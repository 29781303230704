import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History, createBrowserHistory } from 'history';
import { AuthenticationReducer } from './reducers/Authentication.reducer';
import { AlertReducer } from './reducers/Alert.reducer';
import { loginReducer } from './reducers/Login.reducer';
import { NavReducer } from './reducers/Nav.reducer';
import { PostReducer } from './reducers/Post.reducer';
import { PostCategoryReducer } from './reducers/PostCategory.reducer';
import { FilesManagerReducer } from './reducers/FileMmanager.reducer';
import { ProductReducer } from './reducers/Product.reducer';
import { ProductCategoryReducer } from './reducers/ProductCategory.reducer';
import { DashboardReducer } from './reducers/Dashboard.reducer';
import { NFCStockReducer } from './reducers/NFCStock.reducer';
import { Orders } from './reducers/Orders.reducer';

export const RootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    AuthenticationReducer,
    AlertReducer,
    loginReducer,
    NavReducer,
    Posts: PostReducer,
    Dashboard: DashboardReducer,
    PostCategories: PostCategoryReducer,
    Files: FilesManagerReducer,
    Products: ProductReducer,
    ProductCategories: ProductCategoryReducer,
    NFCStockReducer: NFCStockReducer,
    Orders: Orders,
  });
