import { INavActions } from '../models/actions/Nav.model';
import { NavConstant } from '../actionTypes/nav.types.d';

const initialState = {
  type: NavConstant.COLLAPSET,
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  isFullScreen: false, // static can't change,
  menu: { id: '1', type: 'main' },
  defaultPath: '/dashboard/default',
  basename: '/pnode-able/react/default', // only at build time to set, like /pnode-able
  layout: 'vertical', // vertical, horizontal (not available in lite version)
  preLayout: null, // (not available in lite version)
  collapseMenu: false, // mini-menu
  layoutType: 'menu-light', // menu-dark, (menu-light, dark are not available in lite version)
  navIconColor: false,
  headerBackColor: 'header-default', // header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
  navBackColor: 'navbar-default', // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
  navBrandColor: 'brand-default', // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
  navBackImage: false, // not available in lite version
  rtlLayout: false, // not available in lite version
  navFixedLayout: true,
  headerFixedLayout: false, // not available in lite version
  boxLayout: false,
  navDropdownIcon: 'style1', // style1, (style2, style3 are not available in lite version)
  navListIcon: 'style1', // style1, (style2, style3, style4, style5, style6 are not available in lite version)
  navActiveListColor: 'active-default', // active-default, (active-blue, active-red, active-purple, active-lightblue, active-dark are not available in lite version)
  navListTitleColor: 'title-default', // title-default, (title-blue, title-red, title-purple, title-lightblue, title-dark are not available in lite version)
  navListTitleHide: false, // not available in lite version
  configBlock: false, // not available in lite version
  layout6Background:
    'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
  layout6BackSize: '', // used only for pre-layout = layout-6
};

export const NavReducer = (
  state: INavActions = initialState,
  action: INavActions
) => {
  let trigger: any = [];
  let open: any = [];

  switch (action.type) {
    case NavConstant.COLLAPSEM:
      return {
        ...state,
        collapseMenu: !state.collapseMenu,
      };
    case NavConstant.COLLAPSET:
      if (action.menu && action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter((item: string) => {
            if (action.menu) {
              return item !== action.menu.id;
            }
          });
          trigger = trigger.filter((item: string) => {
            if (action.menu) {
              return item !== action.menu.id;
            }
          });
        }

        if (triggerIndex === -1) {
          open = [...open, action.menu.id];
          trigger = [...trigger, action.menu.id];
        }
      } else {
        open = state.isOpen;
        if (state.isTrigger && action.menu) {
          const triggerIndex = state.isTrigger.indexOf(action.menu.id);
          trigger = triggerIndex === -1 ? [action.menu.id] : [];
          open = triggerIndex === -1 ? [action.menu.id] : [];
        }
      }

      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    case NavConstant.NAVCONTENTL:
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    case NavConstant.COLLAPSENL:
      if (action.menu && action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
          open = open.filter((item: string) => {
            if (action.menu) {
              return item !== action.menu.id;
            }
          });
          trigger = trigger.filter((item: string) => {
            if (action.menu) {
              return item !== action.menu.id;
            }
          });
        }
        return {
          ...state,
          isOpen: open,
          isTrigger: trigger,
        };
      }
      return { ...state };
    case NavConstant.FULLSCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen,
      };
    case NavConstant.EXITFULL:
      return {
        ...state,
        isFullScreen: false,
      };
    default:
      return state;
  }
};
