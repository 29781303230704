import { OrdersConstant } from '../actionTypes/Orders.types.d';

export const initialState = {
  pending: false,
  orderlist: [],
  total: 0,
  offset: 0,
  limit: 10,
};

export const Orders = (state = initialState, action: any) => {
  switch (action.type) {
    case OrdersConstant.FETCH_ORDERS_DATA:
      return {
        ...state,
        pending: false,
        orderlist: action.orderData.orders,
        total: action.orderData.total.count,
        offset: action.orderData.offset,
        limit: action.orderData.limit,
      };

    default:
      return state;
  }
};
