export enum PostConstant {
  POST_SUCCESS = 'NEW_POST_SUCCESS',
  POST_ERROR = 'NEW_POST_ERROR',
  POST_REQUEST = 'NEW_POST_REQUEST',
  POST_FETCH_REQUEST = 'POST_FECH_REQUEST',
  POST_FETCH_SUCCEED = 'POST_FETCH_SUCCEED',
  POST_FETCH_ERROR = 'POST_FETCH_ERROR',
  POST_UPDATE_REQUEST = 'UPDATE_POST_REQUEST',
  POST_UPDATE_SUCCEED = 'POST_UPDATE_SUCCEED',
  POST_UPDATE_ERROR = 'POST_UPDATE_ERROR',
}
