import React, { useEffect, useState, ReactNode } from 'react';
import { Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CustomLibraryCard from './CustomLibraryCard';
import { fileUploadRequest } from '../../../../store/actions/file-manager.action';
import {
  FileLibraryListItem,
  FileMeta,
  ReactMediaLibrary,
} from 'react-media-library-daniel-daleva';
import { Field, FieldRenderProps } from 'react-final-form';
import { FieldInput } from '../../../../lib/formhelper';
import { FormApi } from 'final-form';
import { convertBase64 } from '../../../../lib/blobhelper';
import { submitMediaService } from '../../../../services/file-manager.service';
import { errorToast } from '../../../../lib/toasthelper';

interface IProps extends FieldRenderProps<string, HTMLInputElement> {
  title?: string;
  fileList: any;
  setChange?: any;
  uploadFile: any;
  children?: ReactNode;
  type?: string;
  onChange?: any;
  imgSrc?: string;
  form: FormApi;
}

const ReactMediaLibraryWrapper: React.FC<IProps> = (props: IProps) => {
  const [display, setDisplay] = useState<boolean>(false);
  let [imgSrc, setImgsrc] = useState<string | undefined>('default');
  const [fileLibraryList, setFileLibraryList] = useState<FileLibraryListItem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    console.log(props.fileList);
    // TODO Get file list from database
    setFileLibraryList(props.fileList);
    setIsLoading(false);
  });

  async function uploadCallback(
    file: File,
    fileMeta: FileMeta
  ): Promise<boolean> {
    // TODO Upload file to backend APIs
    const files = {
      files: await convertBase64(file),
      fileMeta: fileMeta,
      type: props.type,
    };
    // await props.uploadFile(files);
    const res = await submitMediaService(files);
    console.log('res', res);

    if (res.status == 200) {
      selectCallback(res.data);
      return true;
    } else {
      errorToast('Unable to Upload');
      return false;
    }
  }

  function selectCallback(item: any) {
    // Hide modal
    setDisplay(false);
    // TODO Pass selected file back to client component callback function
    clientSelectCallback(item);
  }

  async function deleteCallback(item: FileLibraryListItem) {
    // TODO Delete file from backend service
    // const result = await fileDelete(item);
    // if (result.success) {
    //     // Deleted file
    //     // TODO Reacquire file list from database
    //     const newFileList = await getNewFileList();
    //     setFileLibraryList(newFileList);
    // }
  }
  function clientSelectCallback(item: FileLibraryListItem) {
    setImgsrc(item.thumbnailUrl);
    props.onChange(item.thumbnailUrl);
    props.form.mutators.setValue(
      `${props.type}.bannerImage`,
      item.thumbnailUrl
    );
  }
  return (
    <>
      <Card className="mb-3 border-0">
        <Card.Header className="border-bottom" style={{ paddingLeft: 0 }}>
          <h6 className="m-0 ">{props.title}</h6>
        </Card.Header>
        <Card.Body className="p-0 m-t-15">
          <img src={props.imgSrc} style={{ width: '200px', height: '200px' }} />
        </Card.Body>
        <Card.Footer style={{ paddingLeft: 0 }}>
          <Field
            name={`${props.type}.bannerImage`}
            type="hidden"
            component={FieldInput}
            value={props.imgSrc}
          />
          <Button
            onClick={() => setDisplay(true)}
            variant="primary"
            type="button"
            className="shadow-2 mb-4"
          >
            Select Image
          </Button>
          <ReactMediaLibrary
            show={display}
            isLoading={isLoading}
            onHide={() => setDisplay(false)}
            fileUploadCallback={uploadCallback}
            fileLibraryList={fileLibraryList}
            fileSelectCallback={selectCallback}
            fileDeleteCallback={deleteCallback}
            libraryCardComponent={CustomLibraryCard}
          />
        </Card.Footer>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  fileList: state.Files.fileList,
});
const mapDispatchToProps = (dispatch: any) => ({
  uploadFile: (files: object) => {
    dispatch(fileUploadRequest({ files: files }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactMediaLibraryWrapper);
