import React from 'react';
import { connect } from 'react-redux';
import { postRequest } from '../../../store/actions/post.action';
import ProductForm from './ProductForm';
import { productRequest } from '../../../store/actions/product.action';

interface IAddPostProps {
  productRequest: any;
}

class AddNewProduct extends React.Component<IAddPostProps, { postRequest: any }> {
  handleSubmit = (values: any) => {
    console.log(values)
    return this.props.productRequest(values);
  };

  render() {
    return (
      <ProductForm
        onSubmit={this.handleSubmit}
        initialValues={{
          productCategories: [],
        }}
        {...{ editMode: false }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, { productRequest })(AddNewProduct);
