import React from 'react';
// import Notfound from '../components/notfound';
import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Login } from '../pages/authPages/login';
import { AuthenticationConstant } from '../store/actionTypes/authentication.type.d';
import AuthRouting from './auth.routes';
import Home from '../pages/dashboard/home';
import Dashboard from '../components/Dashboard';

class Routing extends React.Component<{ AuthenticationReducer?: any }> {
  render() {
    const{type} = this.props.AuthenticationReducer;
    return (
      <Switch>
        {
          type ===  AuthenticationConstant.AUTHENTICATED ? (
            <>
            <Redirect to="/" />
            <Route path="/">
              <Dashboard/>
            </Route>
            </>
          ):(
            <>
            {console.log('unauthnti') }
            <Redirect to="/login"/>
            <Route path="/login">
              <Login/>
              </Route>
           </>
          )
        }
  </Switch>
    );
  }
}

export default connect((state) => state)(Routing);
