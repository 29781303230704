import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';
import { b64toBlob } from '../lib/blobhelper';

export function fetchFilesRequestService() {
  return API.get(PathApi.files)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function submitMediaService(files:any){
  // console.log('service', files);
  let formData = new FormData();
  formData.append('file', b64toBlob(files.files), files.fileMeta.fileName);
  return API.post(`${PathApi.fileUpload}${files.type}`,formData,"multipart/form-data")
    .then((res)=>{
    return res;
  }).catch((err)=>{
    return err.message;
  });
}
