import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';

export function fetchPostsService(filter?: any) {
  let path = filter
    ? PathApi.post + `?filter=${JSON.stringify(filter)}`
    : PathApi.post;
  return API.get(path)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function submitPostService(payload: any) {
  return API.post(PathApi.submitPost, payload)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function updatePostService(id: string, payload: any) {
  return API.patch(PathApi.updatePost + id, payload)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}
