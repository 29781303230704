import React from 'react';
import {
  Card,
  Form,
  ListGroup,
  ListGroupItem,
  Button,
  InputGroup,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import ArrayCheckbox from '../../../redux-form/array.component';
import { Field } from 'react-final-form';

interface Iprops {
  title?: string;
  categories: any;
  catSuccess: boolean;
  currentCategories:any;
  type:string;
}

const SidebarCategories: React.FC<Iprops> = (props) => {
  const { title, categories, catSuccess, currentCategories, type } = props;

  function getChild(item: any, cl = 'px-3') {
    let child;
    if (item.childerns) {
      child = item.childerns.map((child: any, i: number) => {
        return (
          <div key={child.id}>
            <Form.Group className={`pb-2 ${cl}`}>
              <Form.Check
                type="checkbox"
                label="Check me out"
                id={`checkbox-${child.title}`}
                className="checkbox checkbox-fill d-inline"
              >
                <Field
                  id={`checkbox-${child.title}`}
                  name={type}
                  type="checkbox"
                  component={ArrayCheckbox}
                  itemValue={child.id}
                />
                <Form.Check.Label
                  htmlFor={`checkbox-${child.title}`}
                  className="cr"
                >
                  {child.title}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            {child.childerns && getChild(child, 'px-4')}
          </div>
        );
      });
    }
    return child;
  }

  return (
    <Card className="mb-3">
      {console.log(currentCategories)}
      <Card.Header className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </Card.Header>
      <Card.Body className="p-0">
        {/* <Field name={`postCategories[]`} type="hidden" component="input" /> */}
        <ListGroup>
          <ListGroupItem className="px-3 pb-2">
            { categories && categories.map((item: any, index: number) => {
              {console.log(currentCategories ? JSON.stringify(currentCategories).includes(item.id) : 'true')}
              return (
                  <div key={item.id}>
                    <Form.Group className="text-left">
                      <Form.Check
                        type="checkbox"
                        label="Check me out"
                        className={`checkbox checkbox-fill d-inline ${
                          item.childerns && `pb-2`
                        }`}
                      >
                        <Field
                          id={`checkbox-${item.title}`}
                          name={type}
                          type="checkbox"
                          component='input'
                          value={item.id}
                          initialValue={currentCategories ? JSON.stringify(currentCategories).includes(item.id): false}
                        />
                        <Form.Check.Label
                          htmlFor={`checkbox-${item.title}`}
                          className="cr"
                        >
                          {item.title}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                     {item.childerns && getChild(item)}
                  </div>
                );
              })}
          </ListGroupItem>

          <ListGroupItem className="d-flex px-3">
            <InputGroup className="ml-auto">
              <Form.Control placeholder="New category" />
              <InputGroup.Append>
                <Button className="px-2">
                  <i className="material-icons">add</i>
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </ListGroupItem>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  title: 'Categories',
  categories: ownProps.categories,
  catSuccess: !state.PostCategories.categoryList.loading,
  currentCategories: ownProps?.currentCategories,
  type:ownProps.type,
});

export default connect(mapStateToProps, {})(SidebarCategories);
