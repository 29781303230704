import {
  take,
  fork,
  call,
  put,
  cancelled,
  takeLatest,
} from 'redux-saga/effects';
import {
  fetchPostCategoriesService,
  submitPostCategoryService,
} from '../../services/post-category.service';
import {
  Error,
  fetchPostCategoryRequest,
  fetchPostCategorySuccess,
  Success,
} from '../actions/post-category.action';
import { PostCategoryConstant } from '../actionTypes/post-category.types.d';
import {
  IFetchCategoriesResponse,
  ISubmitPostCategoriesResponse,
} from '../models/api/PostCategory.api.model';
import { errorToast, successToast } from '../../lib/toasthelper';

function* submitPostCatSaga(category: any) {
  let categoryInfo: ISubmitPostCategoriesResponse;
  let categoryData;
  try {
    categoryInfo = category.category.category.id
      ? ''
      : yield call(submitPostCategoryService, category.category.category);
    if (categoryInfo.status === 200) {
      categoryData = categoryInfo.data;
      yield put(Success({ message: categoryData.message }));
      yield put(fetchPostCategoryRequest());
      yield call(
        successToast,
        `${
          category.category.category.id ? 'Updated' : 'Added'
        } Category Successfully`
      );
    } else {
      throw categoryInfo.data.error;
    }
  } catch (error: any) {
    yield put(Error(error.message));
    yield call(errorToast, error.message);
  }
}

function* fetchCategoryAPI() {
  try {
    const response: IFetchCategoriesResponse = yield call(
      fetchPostCategoriesService
    );
    console.log(response);
    if (response.status === 200) {
      const categories: any = response.data;
      yield put(fetchPostCategorySuccess(categories[0]));
    } else {
      throw response.data.error;
    }
  } catch (error: any) {}
}

export function* initialPostCategoryWatcher() {
  yield takeLatest(PostCategoryConstant.FETCH_REQUEST, fetchCategoryAPI);
  yield takeLatest(PostCategoryConstant.REQUEST, submitPostCatSaga);
}
