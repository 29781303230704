import { PostConstant } from '../actionTypes/post.types.d';
import { IPostAction } from '../models/actions/Post.model';

export const postRequest = (payload: any): IPostAction => {
  return {
    type: PostConstant.POST_REQUEST,
    post: {
      post: payload.post,
      postCategories: payload.postCategories,
    },
  };
};
export const postSuccess = (message: any): IPostAction => ({
  type: PostConstant.POST_SUCCESS,
  message: message,
});
export const postError = (message: any): IPostAction => ({
  type: PostConstant.POST_ERROR,
  message: message,
});
export const fetchPostRequest = (payload?: any): IPostAction => {
  return {
    type: PostConstant.POST_FETCH_REQUEST,
    filter: payload.filter,
  };
};
export const fetchPostSuccess = (payload: any): IPostAction => {
  return {
    type: PostConstant.POST_FETCH_SUCCEED,
    postList: payload,
  };
};
export const fetchPostError = (payload: any): IPostAction => {
  return {
    type: PostConstant.POST_FETCH_ERROR,
    message: payload.message,
  };
};
