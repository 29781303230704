import { ProductCategoryConstant } from '../actionTypes/product-category.types.d';
import { IProductCategoryAction } from '../models/actions/ProductCategory.model';


export const productCategoryRequest = (payload: any): IProductCategoryAction => ({
  type: ProductCategoryConstant.REQUEST,
  category: {
    category: payload.category,
  },
});

export const Error = (message: any): IProductCategoryAction => ({
  type: ProductCategoryConstant.ERROR,
  message: message,
});

export const Success = (message: any): IProductCategoryAction => ({
  type: ProductCategoryConstant.SUCCESS,
  message: message,
});

export const fetchProductCategoryRequest = (
  ): IProductCategoryAction => ({
    type: ProductCategoryConstant.FETCH_REQUEST,
  });

export const fetchProductCategorySuccess = (payload: any): IProductCategoryAction => ({
  type: ProductCategoryConstant.FETCH_SUCCEED,
  categories:payload
});
export const fetchProductCategoryError = (message: any): IProductCategoryAction => ({
    type: ProductCategoryConstant.FETCH_ERROR,
    message:message
  });

export const loadProductCategory = (payload: any): IProductCategoryAction => ({
  type: ProductCategoryConstant.LOAD_CATEGORY,
  category: payload.category,
});




