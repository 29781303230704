import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchPostsService,
  submitPostService,
  updatePostService,
} from '../../services/post.service';
import {
  fetchPostRequest,
  fetchPostSuccess,
  postError,
  postSuccess,
} from '../actions/post.action';
import { PostConstant } from '../actionTypes/post.types.d';
import {
  IFetchPostResponse,
  ISubmitPostResponse,
} from '../models/api/Post.api.model';
import { errorToast, successToast, warningToast } from '../../lib/toasthelper';
import { push } from 'connected-react-router';
import { fetchProductRequest } from '../actions/product.action';
import { initialState } from '../reducers/Post.reducer';

function* fetchPostsSaga(payload?: any) {
  try {
    const response: IFetchPostResponse = yield call(
      fetchPostsService,
      payload.filter
    );
    if (response.status === 200) {
      const posts: any = response.data;
      yield put(fetchPostSuccess(posts));
      successToast('Post Fetch Success');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    warningToast('Error in Fetching Post. Reinitialize Process');
  }
}
function* submitPostDataSaga(payload?: any) {
  console.log(payload);
  try {
    let response: ISubmitPostResponse = {};
    if (payload.post.post.hasOwnProperty('id')) {
      response = yield call(
        updatePostService,
        payload.post.post.id,
        payload.post
      );
    } else {
      response = yield call(submitPostService, payload.post);
    }
    console.log('response', response);
    if (response.status === 200) {
      const post: any = response.data;
      yield put(postSuccess(post.message));
      yield put(fetchPostRequest({ filter: initialState.filter }));
      yield put(push('/dashboard/post/list'));
      successToast('Post Submitted Successfully');
    } else {
      throw response.data.error;
    }
    console.log(response);
  } catch (error: any) {
    yield put(postError(error.message));
    yield call(errorToast, error.message);
  }
}

export function* initialFetchPostWatcher() {
  yield takeEvery(PostConstant.POST_FETCH_REQUEST, fetchPostsSaga);
  yield takeLatest(PostConstant.POST_REQUEST, submitPostDataSaga);
}
