import React from 'react';
import { Card, Form } from 'react-bootstrap';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { isArray } from 'util';
import slug from 'slug';
import { connect } from 'react-redux';
import RichTextSunEditor from '../RichTextSunEditor';
import { isEmpty } from 'lodash';
import { required } from '../../../../lib/validtionhelper';
import { FieldInput } from '../../../../lib/formhelper';
import { Field  } from 'react-final-form';

interface EditorState {
  editorHtml: string;
  slug: string;
}

interface EditorProps {
  change: (p: string, s: string) => void;
  type:string;
}

class Editor extends React.Component<EditorProps, EditorState> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <Card className="mb-3">
        <Card.Body>
              <div>
                <Form.Group>
                  <Form.Label>
                    Title
                  </Form.Label>
                  <Field
                    id="text-title"
                    type="text"
                    size="lg"
                    className="input-group mb-4"
                    placeholder= 'English Title'
                    validate={required}
                    component={FieldInput}
                    name={`${this.props.type}.title`}
                  />
                </Form.Group>
              </div>
          <Form.Group>
            <Form.Label>Slug</Form.Label>
            <Field
              type="text"
              size="lg"
              className="input-group mb-4"
              placeholder="new-post"
              readOnly={true}
              validate={required}
              component={FieldInput}
              name={`${this.props.type}.slug`}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
          <Field
            id="text-description"
            type="hidden"
            className="input-group mb-4"
            placeholder="description"
            component={RichTextSunEditor}
            name={`${this.props.type}.description`}
          />
          </Form.Group>
          {this.props.type ==='product' &&
            <Form.Group className={'m-t-30'}>
            <Form.Label>Additional Information</Form.Label>
            <Field
            id="text-description"
            type="textarea"
            className="input-group mb-4"
            placeholder="Put Extra Details here"
            component={'textarea'}
            name={`${this.props.type}.additionalInfo`}
            />
            </Form.Group>
           }
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({


});
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
