import { NFCStockConstant } from '../actionTypes/NFCStock.types.d';
import { INFCStockAction } from '../models/actions/NFCStock.model';

export const initialState = {
  pending: false,
  success: false,
  nfclist: [],
  message: {},
  errors: {},
};

export const NFCStockReducer = (
  state = initialState,
  action: INFCStockAction
) => {
  //   console.log('action', action);

  switch (action.type) {
    case NFCStockConstant.FETCH_NFC_DATA:
      return {
        ...state,
        pending: false,
        success: true,
      };
    case NFCStockConstant.FETCH_NFC_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        nfclist: action.nfclist,
      };

    case NFCStockConstant.DELETE_NFC:
      return {
        ...state,
        pending: false,
        success: true,
      };
    case NFCStockConstant.DELETE_NFC_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        nfclist: action.nfclist,
      };

    case NFCStockConstant.NFC_STOCK_UPLOAD:
      return {
        ...state,
        pending: false,
        success: true,
      };
    case NFCStockConstant.NFC_STOCK_UPLOAD_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        nfclist: action.nfclist,
      };
    default:
      return state;
  }
};
