import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
// import NavSearch from './NavSearch';
import { FiMinimize, FiMaximize } from 'react-icons/fi';
import { NavConstant } from '../../../../../store/actionTypes/nav.types.d';

type props = {
  isFullScreen: boolean;
  rtlLayout: boolean;
  onFullScreen: any;
  windowWidth: number;
};

class NavLeft extends Component<props> {
  render() {
    let iconFullScreen = ['feather'];
    iconFullScreen = this.props.isFullScreen
      ? [...iconFullScreen, 'icon-minimize']
      : [...iconFullScreen, 'icon-maximize'];

    let navItemClass = ['nav-item'];
    if (this.props.windowWidth <= 575) {
      navItemClass = [...navItemClass, 'd-none'];
    }
    let dropdownRightAlign = false;
    if (this.props.rtlLayout) {
      dropdownRightAlign = true;
    }

    return (
      <>
        <ul className="navbar-nav mr-auto">
          {/* <li>
            <a
              href="#!"
              className="full-screen"
              onClick={this.props.onFullScreen}
            >
              <i className={iconFullScreen.join(' ')} />
            </a>
          </li> */}
          {/* <li className={navItemClass.join(' ')}>
            <Dropdown alignRight={dropdownRightAlign}>
              <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                Dropdown
              </Dropdown.Toggle>
              <ul>
                <Dropdown.Menu>
                  <li>
                    <a className="dropdown-item" href="#!">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#!">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#!">
                      Something else here
                    </a>
                  </li>
                </Dropdown.Menu>
              </ul>
            </Dropdown>
          </li> */}
          {/* <li className="nav-item"><NavSearch/></li> */}
        </ul>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isFullScreen: state.NavReducer.isFullScreen,
    rtlLayout: state.NavReducer.rtlLayout,
    windowWidth: document.body.clientWidth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFullScreen: () => dispatch({ type: NavConstant.FULLSCREEN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavLeft);
