import { ProductConstant } from '../actionTypes/product.types.d';
import { IProductAction } from '../models/actions/Product.model';

export const productRequest = (payload: any): IProductAction => {
  return {
    type: ProductConstant.PRODUCT_REQUEST,
    product: {
      product: payload.product,
      productCategories: payload.productCategories,
    },
  };
};
export const productSuccess = (message: any): IProductAction => ({
  type: ProductConstant.PRODUCT_SUCCESS,
  message: message,
});
export const productError = (message: any): IProductAction => ({
  type: ProductConstant.PRODUCT_ERROR,
  message: message,
});
export const fetchProductRequest = (payload?: any): IProductAction => {
  return {
    type: ProductConstant.PRODUCT_FETCH_REQUEST,
    filter: payload.filter,
  };
};
export const fetchProductSuccess = (payload: any): IProductAction => {
  return {
    type: ProductConstant.PRODUCT_FETCH_SUCCEED,
    productList: payload,
  };
};
export const fetchProductError = (payload: any): IProductAction => {
  return {
    type: ProductConstant.PRODUCT_FETCH_ERROR,
    message: payload.message,
  };
};
