import { AlertConstant } from '../actionTypes/alert.types.d';
import { IAlertAction } from '../models/actions/Alert.model';

export const Success = (payload: any): IAlertAction => ({
  type: AlertConstant.SUCCESS,
  tag: payload.tag,
  message: payload.message,
});

export const Error = (payload: any): IAlertAction => ({
  type: AlertConstant.ERROR,
  tag: payload.tag,
  message: payload.message,
});

export const Clear = () => ({ type: AlertConstant.CLEAR });
