import React from 'react';

import SunEditor, { buttonList } from 'suneditor-react';
import { isArray } from 'util';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
interface IRichTextProps {
  input: any;
  meta: any;
}

class RichTextSunEditor extends React.Component<IRichTextProps> {
  handleChange(e: any, content: any) {
    const { input } = this.props;
    input.onChange(content);
  }

  render() {
    const { input } = this.props;
    buttonList.complex.map((item, index) => {
     if(isArray(item) && item.find((elem) => elem == 'preview' )) {
        buttonList.complex.splice(index);
      }
    });
    return (
      <>
        <SunEditor
          width="100%"
          onBlur={this.handleChange.bind(this)}
          lang='en'
          setOptions={{
            minHeight: "40vh",
            maxHeight: "20vh",
            buttonList: [...buttonList.complex],
          }}
          setContents={input.value}
          name={input.name}
        />
      </>
    );
  }
}

export default RichTextSunEditor;
