import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { sanitizeNU } from '../../../utils/stringHelpers';
import { productRequest } from '../../../store/actions/product.action';
import ProductForm from './ProductForm';

interface IEditProps {
  initialValue: any;
  productRequest: any;
}

class EditProduct extends React.Component<IEditProps, { postRequest: any }> {
  handleSubmit = (values: any) => {
    return this.props.productRequest(sanitizeNU(values));
  };

  render() {
    const { initialValue } = this.props;
    return (
      <>
        {(!isEmpty(initialValue) && (
          <ProductForm
            onSubmit={this.handleSubmit}
            initialValues={{
              product: {
                id: initialValue?.id,
                title: initialValue?.title,
                slug: initialValue?.slug,
                bannerImage:
                  initialValue?.bannerImage === null
                    ? undefined
                    : initialValue?.bannerImage,
                description: initialValue?.description,
                additionalInfo: initialValue?.additionalInfo,
                regularPrice: initialValue?.regularPrice,
                salePrice: initialValue?.salePrice,
              },
              productCategories:
                (initialValue?.hasOwnProperty('categories') &&
                  initialValue.categories.map((item: any) => item.id)) ||
                [],
            }}
            {...{ editMode: true }}
          />
        )) || <Redirect to="/dashboard/product/list" />}
      </>
    );
  }
}
const mapStateToProps = (state: any, ownProps: any) => {
  return {
    initialValue: ownProps.location.state.product,
  };
};
export default connect(mapStateToProps, { productRequest })(EditProduct);
