import React, { Component, Fragment } from 'react';
import { Col, Card, Badge } from 'react-bootstrap-v5';
import PageTitle from '../../../components/Dashboard/common/PageTitle';
import { connect } from 'react-redux';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { PostTableHeader } from '../../../components/Dashboard/common/PostTableHeader';
import { Link } from 'react-router-dom';
import { initialState } from '../../../store/reducers/Post.reducer';
import { fetchPostRequest } from '../../../store/actions/post.action';
import { API } from '../../../config/api.config';
import { PathApi } from '../../../config/api.path.config';
import { errorToast, successToast } from '../../../lib/toasthelper';

interface Iprops {
  posts: any;
  categories: [];
  getCategoryPost?: (filter: any, fetchType: string, catId: string) => void;
  getPosts: (filter: any) => void;
  router?: any;
  route?: any;
  routerWillLeave?: (nextLocation: any) => void;
}

class PostList extends Component<Iprops> {
  constructor(props: Iprops) {
    super(props);
    this.onCategoryChange = this.onCategoryChange.bind(this);
  }

  onCategoryChange(e: any) {
    console.log(e);
    if (e.value !== 'all') {
      const filter = {
        include: [
          {
            relation: 'post',
          },
        ],
      };
      // this.props.getCategoryPost(filter, "categoryPost", e.value);
    } else {
      return this.props.getPosts(initialState.filter);
    }
  }

  componentDidMount() {
    this.props.getPosts(initialState.filter);
  }

  deletePost = (id: string) => {
    API.delete(PathApi.post + '/' + id)
      .then((data: any) => {
        successToast('Deleted successfully');
        this.props.getPosts(initialState.filter);
      })
      .catch((error: any) => {
        errorToast('Unable to delete');
      });
  };

  render() {
    const { posts } = this.props;
    return (
      <>
        <PageTitle title="All Post" subtitle="View ALL" className="" />
        {/* <CategorySelect onChange={this.onCategoryChange} className="col-md-2 pb-2 pl-0" /> */}
        <PostTableHeader />
        {posts &&
          posts.map((item: any, i: number) => {
            return (
              <Card
                key={item.id}
                className={
                  'card-list ' + (i % 2 === 0 ? 'bg-white' : 'bg-light')
                }
              >
                <Card.Body>
                  <div className="row no-gutters">
                    <Col md={5} xs={12}>
                      <Card.Title>{item.title}</Card.Title>
                    </Col>
                    <Col md={3} xs={12}>
                      <Card.Text>{item.slug}</Card.Text>
                    </Col>
                    <Col
                      md={2}
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Card.Text>
                        {(item.categories &&
                          item.categories.map((cat: any, j: number) => {
                            console.log(cat);
                            return (
                              <Badge
                                key={cat.id}
                                pill
                                bg={i % 2 === 0 ? 'primary' : 'secondary'}
                                className="ml-1"
                              >
                                {cat.title}
                              </Badge>
                            );
                          })) || (
                          <Badge pill bg="warning">
                            UnCategorized
                          </Badge>
                        )}
                      </Card.Text>
                    </Col>
                    <Col
                      md={2}
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Link
                        to={{
                          pathname: `/dashboard/post/edit/type?edit=${item.id}`,
                          state: { post: item },
                        }}
                        className="btn btn-icon btn-rounded btn-outline-warning"
                      >
                        <FaEdit />
                      </Link>
                      <a
                        href="#!"
                        className="btn btn-icon btn-rounded btn-outline-danger"
                        onClick={() => this.deletePost(item.id)}
                      >
                        <FaTrash />
                      </a>
                    </Col>
                    <Col md={1} xs={12}></Col>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  posts: state.Posts.posts,
  categories: state.PostCategories.categoryList.categories,
  router: state.router,
});
const mapDispatchToProps = (dispatch: any) => ({
  // getCategoryPost: (filter: object, fetchType: string, catId: string) => {
  //     dispatch(fetchCategoryPostRequest({ filter: filter, fetchType: fetchType, catId: catId }))
  // },
  getPosts: (filter: object) => {
    dispatch(fetchPostRequest({ filter: filter }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PostList);
