import { PostCategoryConstant } from "../actionTypes/post-category.types.d"
import { IPostCategoryAction } from "../models/actions/PostCategory.model"
import { IPostCategoryState } from "../models/reducers/PostCategoryReducer.model"

const initialState:IPostCategoryState = {
    category:{
        submitting:false,
        error:false,
        success:false,
        category:{}
      },
      categoryList:{
        loading:false,
        currentPage:0,
        nextPage:1,
        categories:[]
      },
      categoryTypeId:null,
      categoryTypeName:null,
}

export const PostCategoryReducer = (state = initialState, action: IPostCategoryAction) => {
    switch (action.type) {
        case PostCategoryConstant.FETCH_REQUEST:
            return {
                ...state,
                categoryList: Object.assign({}, state.categoryList , {loading:true}) ,

            }
        case PostCategoryConstant.FETCH_SUCCEED:
            return {
                ...state,
               categoryList:Object.assign({},state.categoryList,{
                loading:false,
                currentPage: state.categoryList.currentPage+1,
                nextPage:state.categoryList.nextPage+1,
                categories: action?.categories.categories
               }),
               categoryTypeId:action.categories.id,
               categoryTypeName:action.categories.name
            }
      case PostCategoryConstant.LOAD_CATEGORY:
        console.log(action);
        return {
          ...state,
          category:Object.assign({}, state.category , {category:action.category}) ,
          message: {
            body: 'Category Loaded'
          },
          errors: {}
        }

       case PostCategoryConstant.REQUEST:
        return {
            ...state,
            category: Object.assign({}, state.category , {submitting:true}),
        }
        case PostCategoryConstant.SUCCESS:
          return {
              ...state,
            category: Object.assign({}, state.category , {submitting:false, success:true}),
            message: {
                  body: action.message
              },
              errors: {}
          }
        case PostCategoryConstant.ERROR:
            return {
              ...state,
              category: Object.assign({}, state.category , {submitting:false,error:true, message:action.message}),
            }
        default:
            return state;

    }
}
