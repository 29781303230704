/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { PathApi } from '../../../../config/api.path.config';
import { API } from '../../../../config/api.config';
import {
  successToast,
  warningToast,
  errorToast,
} from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';

class Index extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true,
      orderData: null,
      status: null,
    };
  }

  componentDidMount() {
    console.log('***', this.props.match.params);
    this.getUserData();
  }

  getUserData = () => {
    API.get(PathApi.orders + '/' + this.props.match.params.orderId)
      .then((data: any) => {
        console.log('data', data);
        let orderData = data.data;
        this.setState({ orderData });
        successToast('Order data loaded');
      })
      .catch((error: any) => {
        this.setState({ userData: null, isLoading: false });
        warningToast('Error in loading user data NFC data');
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateOrderStatus = () => {
    this.setState({ isLoading: true });

    const postdata = {
      status: this.state.status,
    };
    API.patch(
      PathApi.updateOrderStatus + this.props.match.params.orderId,
      postdata
    )
      .then((data: any) => {
        console.log('data', data);
        successToast('Order data Updated');
        this.getUserData();
      })
      .catch((error: any) => {
        console.log('error', error);
        this.setState({ isLoading: false });
        errorToast('Error in updating order data');
      });
  };

  deleteOrder = () => {
    this.setState({ isLoading: true });
    API.delete(PathApi.orders + '/' + this.props.match.params.orderId)
      .then((data: any) => {
        console.log('data', data);
        successToast('Order data Deleted');
        this.props.history.goBack();
      })
      .catch((error: any) => {
        console.log('error', error);
        this.setState({ isLoading: false });
        errorToast('Error in deleting order data');
      });
  };

  render() {
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    const { orderData } = this.state;
    return (
      <Container>
        <Col md="auto">
          <Row
            className="justify-content-md-center"
            style={{ paddingTop: 20, marginBottom: 10 }}
          >
            <h1>Order Details</h1>
          </Row>
          <div className="col-md-12">
            <p>
              <span style={{ fontWeight: 'bold' }}>Paypal Invoice Id:- </span>
              {orderData?.invoiceId}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Invoice Id:- </span>
              TYC/{orderData?.invoiceNumber}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Billing Name:- </span>
              {orderData?.billingName}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Shipped Address:- </span>
              {orderData?.shippedAddress}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Payment Status:- </span>
              {orderData?.paymentStatus}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Currency:- </span>
              {orderData?.currency}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Total Amount:- </span>
              {orderData?.totalAmount}
            </p>
            <div className="col-sm-12" style={{ margin: 5 }}>
              <div style={{ fontWeight: 'bold' }} className="col-sm-3">
                Order status:-{' '}
              </div>
              <div className="col-sm-3">
                <select
                  className="form-select"
                  onChange={(e) => this.setState({ status: e.target.value })}
                >
                  {[
                    'ORDER_RECEIVED',
                    'PROCESSING_ORDER',
                    'IN_TRANSIST',
                    'SUCCESSFUL',
                    'CANCELLED',
                  ].map((e: string, i: any) => {
                    return (
                      <option
                        key={i}
                        value={e}
                        selected={e == orderData?.status ? true : false}
                      >
                        {e}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-12" style={{ margin: 5 }}>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => this.updateOrderStatus()}
              >
                Update
              </button>
            </div>
            <div className="col-12" style={{ margin: 5 }}>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteOrder()}
              >
                Delete Order
              </button>
            </div>
          </div>

          <Row
            className="justify-content-md-center"
            style={{ paddingTop: 20, marginBottom: 10 }}
          >
            <h3>Order Items</h3>
          </Row>
          <div className="col-sm-6">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Sl No
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Product Name
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Quantity
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    unit_amount
                  </th>
                  <th scope="col" style={{ fontSize: 14 }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {orderData.items.length > 0 &&
                  orderData.items.map((e: any, i: any) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{e.name}</td>
                        <td>{e.quantity}</td>
                        <td>{e.unit_amount.value}</td>
                        <td>{(e.quantity * e.unit_amount.value).toFixed(2)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Col>
        <Row style={{ height: 200 }}></Row>
      </Container>
    );
  }
}
export default Index;
