import React from 'react';
import { Row, Col, Card, Table, Button, Dropdown, Form } from 'react-bootstrap';
import { API } from '../../../../config/api.config';
import { PathApi } from '../../../../config/api.path.config';
import { successToast, warningToast } from '../../../../lib/toasthelper';
import RJSLoader from '../../../../components/RJSLoader';

class Index extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      isLoading: false,
    };
  }
  componentDidMount() {}

  createNfc = () => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append(
      'file',
      this.state.file,
      (Math.random() + 1).toString(36).substring(7)
    );
    API.post(PathApi.uploadNfcData, formData)
      .then((data: any) => {
        console.log(data);
        successToast('NFC List loaded');
        this.setState({ file: null, isLoading: false });
      })
      .catch((error: any) => {
        this.setState({ isLoading: false });
        console.log(error);
        warningToast('Error in Uploading NFC List');
      });
  };

  render() {
    if (this.state.isLoading) {
      return <RJSLoader />;
    }
    return (
      <Row>
        <Row>
          <div>
            <div style={{ flexDirection: 'row' }}>
              <div className="form-group mb-4 col-4">
                <label htmlFor="exampleInputEmail1">Choose Upload file</label>
                <input
                  type="file"
                  className="form-control"
                  id="startDate"
                  aria-describedby="dateHelp"
                  onChange={(e: any) =>
                    this.setState({ file: e.target.files[0] })
                  }
                />
              </div>
            </div>
            <Button
              variant="primary"
              className="shadow-2 mb-4"
              onClick={() => this.createNfc()}
            >
              Upload
            </Button>
          </div>
        </Row>
      </Row>
    );
  }
}

export default Index;
