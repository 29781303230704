import React from 'react';
import { GiNautilusShell } from 'react-icons/all';
import unnamed from '../../../../../assets/images/DE.png';
const NavLogo = (props: any) => {
  let toggleClass = ['mobile-menu'];
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <>
      <div className="navbar-brand header-logo" style={{paddingLeft:'30%', paddingTop:'10%'}}>
        <a className="b-brand">
          <div className="b-bg text-dark" >
            <img src={unnamed} style={styles}/>
          </div>
        </a>
      </div>
    </>
  );
};
const styles = {
        width: '500%',
        height:'200%',
}
export default NavLogo;
