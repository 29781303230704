import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';

export function fetchProductCategoriesService() {
  return API.get(PathApi.productCategories)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}
