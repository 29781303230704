import { PostConstant } from "../actionTypes/post.types.d"
import { IPostAction } from "../models/actions/Post.model"

export const initialState = {
    pending: false,
    success: false,
    submitting:false,
    post:{
        submitting:false,
        post:{},
        postCategories:[]
    },
    posts: [],
    filter: {
        "include": [
            {
             "relation": "categories"
            }
        ]
    },
fetchType:"post",
message: {},
errors: {},
}

export const PostReducer = (state = initialState, action: IPostAction) => {
    switch (action.type) {
        case PostConstant.POST_FETCH_REQUEST:
            return {
                ...state,
                pending: true,
                success: false,
                filter: action.filter
            }
        case PostConstant.POST_FETCH_SUCCEED:
            return {
                ...state,
                pending: false,
                success: true,
                posts: action.postList,
            }
        case PostConstant.POST_REQUEST:
        return {
            ...state,
            pending: true,
            success: false,
            submitting:true,
            post: {
                    post:action.post,
                    postCategories: action.post?.postCategories,
                },
        }
        case PostConstant.POST_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                submitting:false,
                message: {
                    body: action.message
                },
                errors: {}
            }
        case PostConstant.POST_ERROR:
            return {
                ...state,
                pending: false,
                success: false,
                message: {},
                errors: {
                    body: action.message.toString()
                }
            }
        default:
            return state;
    }
}
