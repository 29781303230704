import { API } from '../config/api.config';
import { PathApi } from '../config/api.path.config';

export function fetchProductsService(filter?: any) {
  let path = filter
    ? PathApi.product + `?filter=${JSON.stringify(filter)}`
    : PathApi.post;
  return API.get(path)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function submitProductService(payload: any) {
  return API.post(PathApi.submitProduct, payload)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}

export function updateProductService(id: string, payload: any) {
  return API.patch(PathApi.updateProduct + id, payload)
    .then((data: any) => {
      return data;
    })
    .catch((error: any) => {
      return error.response;
    });
}
