import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { errorToast, successToast, warningToast } from '../../lib/toasthelper';
import {
  fetchTapsService,
  fetchUserAnalyticsService,
  fetchUsersService,
} from '../../services/dashboard.service';
import {
  loadDashboardData,
  loadTapCountData,
  loadUserAnalyticsData,
} from '../actions/dashboard.action';
import { DashboardConstant } from '../actionTypes/dashboard.types.d';
import { IFetchGetResponse } from '../models/api/Dashboard.api.model';

function* fetchUsersSaga(payload?: any) {
  try {
    const response: IFetchGetResponse = yield call(fetchUsersService);
    console.log('response', response);

    if (response.status === 200) {
      const users: any = response.data;
      yield put(loadDashboardData(users));
      successToast('User data loaded');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    warningToast('Error in Fetching Users. Reinitialize Process');
  }
}

function* fetchUserAnalyticsSaga(payload?: any) {
  try {
    const response: IFetchGetResponse = yield call(
      fetchUserAnalyticsService,
      payload
    );
    console.log('response', response);

    if (response.status === 200) {
      const data: any = response.data;
      yield put(loadUserAnalyticsData(data));
      successToast('User analytics data loaded');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    warningToast('Error in Fetching Tap count. Reinitialize Process');
  }
}

function* fetchTapsSaga(payload?: any) {
  try {
    const response: IFetchGetResponse = yield call(fetchTapsService, payload);
    console.log('response Tap', response);

    if (response.status === 200) {
      const data: any = response.data;
      yield put(loadTapCountData(data));
      successToast('User Tap count loaded');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    warningToast('Error in Fetching Tap count. Reinitialize Process');
  }
}

export function* initialFetchDashboardWatcher() {
  yield takeEvery(DashboardConstant.FETCH, fetchUsersSaga);
  yield takeEvery(DashboardConstant.FETCH_TAP_COUNT, fetchTapsSaga);
  yield takeEvery(
    DashboardConstant.FETCH_USER_ANALYTICS,
    fetchUserAnalyticsSaga
  );
}
