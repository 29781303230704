export enum NFCStockConstant {
  FETCH_ERROR = 'FETCH_NFC_ALERT_ERROR',

  FETCH_NFC_DATA = 'FETCH_NFC_DATA',
  FETCH_NFC_DATA_SUCCESS = 'FETCH_NFC_DATA_SUCCESS',

  DELETE_NFC = 'DELETE_NFC',
  DELETE_NFC_SUCCESS = 'DELETE_NFC_SUCCESS',

  NFC_STOCK_UPLOAD = 'NFC_STOCK_UPLOAD',
  NFC_STOCK_UPLOAD_SUCCESS = 'NFC_STOCK_UPLOAD_SUCCESS',
}
