import React from 'react';
import FullScreen from 'react-full-screen';
import { connect } from 'react-redux';
import SidebarNavigation from './navigations/SidebarNavigation';
import TopNav from './navigations/TopNav';
import AuthRouting from '../../routes/auth.routes';

type props = {
  history?: any;
  isFullScreen: boolean;
  onFullScreenExit: any;
  windowWidth: number;
  layout: string;
  collapseMenu: boolean;
};

class Dashboard extends React.Component<props> {
  fullScreenExitHandler = () => {
    if (!document.fullscreenElement && !document.fullscreenEnabled) {
      this.props.onFullScreenExit();
    }
  };

  componentWillMount() {
    if (
      this.props.windowWidth > 992 &&
      this.props.windowWidth <= 1024 &&
      this.props.layout !== 'horizontal'
    ) {
      // this.props.onComponentWillMount();
    }
  }

  mobileOutClickHandler() {
    if (this.props.windowWidth < 992 && this.props.collapseMenu) {
      // this.props.onComponentWillMount();
    }
  }

  render() {
    console.log('dashboard loaded')
    return (
      <>
        <SidebarNavigation />
        <TopNav />
        <main className="pcoded-main-container mr-3">
          <AuthRouting />
        </main>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isFullScreen: state.NavReducer.isFullScreen,
    onFullScreenExit: state.NavReducer.onFullScreenExit,
    windowWidth: document.body.clientWidth,
    layout: state.NavReducer.layout,
    collapseMenu: state.NavReducer.collapseMenu,
  };
};

export default connect(mapStateToProps, {})(Dashboard);
