import createReducer from '../../lib/createReducer';
import { LoginConstant } from '../actionTypes/login.types.d';
import {
  ILoginRequestState,
  ILoginResponseState,
} from '../models/actions/Login.model';
import { ILoginResponse } from '../models/api/Login.api.model';
import { ILoginReducerState } from '../models/reducers/LoginReducer.model';

const initialState: ILoginReducerState = {
  pending: false,
  success: false,
  message: {},
  submitting:false,
  errors: {},
  isLoading: false,
};

export const loginReducer = createReducer(initialState, {
  [LoginConstant.LOGIN_REQUEST](
    state: ILoginReducerState,
    action: ILoginRequestState
  ) {
    return {
      ...state,
      isLoading: true,
      submitting:true,
    };
  },
  /*[LoginConstant.LOGIN_ENABLE_LOADER](state: ILoginState) {
    return { ...state, isLoading: true };
  },
  [LoginConstant.LOGIN_DISABLE_LOADER](state: ILoginState) {
    return { ...state, isLoading: false };
  },*/
  [LoginConstant.LOGIN_RESPONSE](
    state: ILoginReducerState,
    action: ILoginResponseState
  ) {
    console.log(action);
    return {
      ...state,
      isLoading: false,
      submitting:false,
      message: action.response.message,
    };
  },
  [LoginConstant.LOGIN_FAILED](
    state: ILoginReducerState = initialState,
    action: ILoginResponseState
  ) {
    console.log(action)
    return {
      ...state,
      isLoggedIn: false,
      isLoading: false,
      submitting:false,
      errors:action.response.errors,
      message: action.response.message,
    };
  },

  [LoginConstant.LOG_OUT](state: ILoginReducerState) {
    console.log(state);
    return initialState;
  },
});
