import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { errorToast, successToast, warningToast } from '../../lib/toasthelper';
import { IProductResponse } from '../models/api/Product.api.model';
import {
  fetchProductRequest,
  fetchProductSuccess,
  productError,
  productSuccess,
} from '../actions/product.action';
import { ProductConstant } from '../actionTypes/product.types.d';
import {
  fetchProductsService,
  submitProductService,
  updateProductService,
} from '../../services/product.service';
import { push } from 'connected-react-router';
import { initialState } from '../reducers/Post.reducer';

function* fetchProductsSaga(payload?: any) {
  try {
    const response: IProductResponse = yield call(
      fetchProductsService,
      payload.filter
    );
    console.log(response);
    if (response.status === 200) {
      const posts: any = response.data;
      yield put(fetchProductSuccess(posts));
      successToast('Product Fetch Success');
    } else {
      throw response.data.error;
    }
  } catch (error: any) {
    warningToast('Error in Fetching Product. Reinitiate Process');
  }
}

function* submitProductDataSaga(payload?: any) {
  try {
    let response: IProductResponse = {};
    if (payload.product.product.hasOwnProperty('id')) {
      response = yield call(
        updateProductService,
        payload.product.product.id,
        payload.product
      );
    } else {
      response = yield call(submitProductService, payload.product);
    }
    console.log('response', response);

    if (response.status === 200) {
      const product: any = response.data;
      yield put(productSuccess(product.message));
      yield put(fetchProductRequest({ filter: initialState.filter }));
      yield put(push('/dashboard/product/list'));
      successToast('Product Submitted Successfully');
    } else {
      throw response.data.error;
    }
    console.log(response);
  } catch (error: any) {
    yield put(productError(error.message));
    yield call(errorToast, error.message);
  }
}
export function* initialFetchProductWatcher() {
  yield takeEvery(ProductConstant.PRODUCT_FETCH_REQUEST, fetchProductsSaga);
  yield takeLatest(ProductConstant.PRODUCT_REQUEST, submitProductDataSaga);
}
