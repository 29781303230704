import { ILoginRequest, ILoginResponse } from '../models/api/Login.api.model';
import { LoginConstant } from '../actionTypes/login.types.d';

export function requestLogin(request: ILoginRequest) {
  return {
    type: LoginConstant.LOGIN_REQUEST,
    request,
  };
}

export function loginFailed(response:any) {
  return {
    type: LoginConstant.LOGIN_FAILED,
    response,
  };
}
export function onLoginResponse(response: ILoginResponse) {
  return {
    type: LoginConstant.LOGIN_RESPONSE,
    response,
  };
}

/*export function enableLoader() {
  return {
    type: LoginConstant.LOGIN_ENABLE_LOADER,
  };
}

export function disableLoader() {
  return {
    type: LoginConstant.LOGIN_DISABLE_LOADER,
  };
}*/

export function logout() {
  return {
    type: LoginConstant.LOG_OUT,
  };
}
