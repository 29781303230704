/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import { fetchDashboardData } from '../../store/actions/dashboard.action';
import { DataTableList, PTag, textFilter } from '../../components/DataTable';
import { Link } from 'react-router-dom';

interface IMainProps {
  loadData: () => void;
  loadCategory: () => void;
  dashboardData: any;
}

class Main extends React.Component<IMainProps> {
  componentDidMount() {
    this.props.loadData();
    console.log('this.props.dashboardData', this.props.dashboardData);
  }
  columns: any = [
    {
      formatter: (cell: any, row: any) => <PTag text={row.id} />,
      text: 'ID',
      dataField: 'id',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <a href={`https://tapyourchip.com/${row.id}`} target={'_blank'}>
          <PTag text={`https://tapyourchip.com/${row.id}`} />
        </a>
      ),
      text: 'Profile Link',
      dataField: 'id',
      sort: true,
      filter: false,
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.name} />,
      text: 'Name',
      dataField: 'name',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.username} />,
      text: 'Username',
      dataField: 'username',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.email} />,
      text: 'Email',
      dataField: 'email',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => <PTag text={row.countryCode} />,
      text: 'Country Code',
      dataField: 'countryCode',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <PTag
          text={
            (row?.callingCode ?? '') +
            ' ' +
            (row?.mobile ? parseInt(row?.mobile).toString() : '')
          }
        />
      ),
      text: 'Mobile',
      dataField: 'mobile',
      sort: true,
      filter: textFilter(),
    },
    {
      formatter: (cell: any, row: any) => (
        <Col>
          <Link
            to={{
              pathname: `/dashboard/userDetails/${row.id}`,
            }}
          >
            <Button variant="primary" size="sm">
              View
            </Button>
          </Link>
          <Link
            to={{
              pathname: `/dashboard/userDetails/${row.id}/edit`,
            }}
          >
            <Button variant="warning" size="sm">
              Edit
            </Button>
          </Link>
        </Col>
      ),
      text: 'Action',
      dataField: 'id',
    },
  ];

  render() {
    const { dashboardData } = this.props;
    return (
      <Row>
        <Row>
          {dashboardData.users.length > 0 && (
            <DataTableList
              title="USERS LIST"
              data={dashboardData.users}
              columns={this.columns}
            />
          )}
          {/* <DataTableList
            title="USERS LIST"
            data={dashboardData.users}
            columns={this.columns}
          /> */}
        </Row>
        <Row md={12} xl={11}>
          {/* <Card className="card-social row">
            <Card.Body className="border-bottom">
              <div className="row align-items-center justify-content-center card-active">
                <div className="col-auto">
                </div>
                <div className="col text-right">
                  <h3>{'item.hindiTitle'}</h3>
                  <h5 className="text-c-green mb-0">
                    <span className="text-muted">{'item.title'}</span>
                  </h5>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="card-social row">
            <Card.Body className="border-bottom">
              <div className="row align-items-center justify-content-center card-active">
                <div className="col-auto">
                </div>
                <div className="col text-right">
                  <h3>{'item.hindiTitle'}</h3>
                  <h5 className="text-c-green mb-0">
                    <span className="text-muted">{'item.title'}</span>
                  </h5>
                </div>
              </div>
            </Card.Body>
          </Card> */}
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  dashboardData: state.Dashboard,
});
const mapDispatchToProps = (dispatch: any) => ({
  loadData: () => dispatch(fetchDashboardData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Main);
