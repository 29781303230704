import { IProductCategoryState } from '../models/reducers/ProductCategoryReducer.model';
import { IProductCategoryAction } from '../models/actions/ProductCategory.model';
import { ProductCategoryConstant } from '../actionTypes/product-category.types.d';

const initialState:IProductCategoryState = {
    category:{
        submitting:false,
        category:{
          parentId:0
        }
      },
      categoryList:{
        loading:false,
        currentPage:0,
        nextPage:1,
        categories:[]
      },
      categoryTypeId:null,
      categoryTypeName:null,
}

export const ProductCategoryReducer = (state = initialState, action: IProductCategoryAction) => {
    switch (action.type) {
        case ProductCategoryConstant.FETCH_REQUEST:
            return {
                ...state,
                categoryList: Object.assign({}, state.categoryList , {loading:true}) ,

            }
        case ProductCategoryConstant.FETCH_SUCCEED:
            console.log(action);
            return {
                ...state,
               categoryList:Object.assign({},state.categoryList,{
                loading:false,
                currentPage: state.categoryList.currentPage+1,
                nextPage:state.categoryList.nextPage+1,
                categories: action?.categories.categories
               }),
               categoryTypeId:action.categories.id,
               categoryTypeName:action.categories.name
            }
      case ProductCategoryConstant.LOAD_CATEGORY:
        console.log(action);
        return {
          ...state,
          category:Object.assign({}, state.category , {category:action.category}) ,
          message: {
            body: 'Category Loaded'
          },
          errors: {}
        }

      case ProductCategoryConstant.REQUEST:
        return {
          ...state,
          category: Object.assign({}, state.category , {submitting:true}),
        }
      case ProductCategoryConstant.SUCCESS:
        return {
          ...state,
          category: Object.assign({}, state.category , {submitting:false, success:true}),
          message: {
            body: action.message
          },
          errors: {}
        }
      case ProductCategoryConstant.ERROR:
        return {
          ...state,
          category: Object.assign({}, state.category , {submitting:false,error:true, message:action.message}),
        }
        default:
            return state;

    }
}
