import React from 'react';
import PostForm from './PostForm';
import { connect } from 'react-redux';
import { postRequest } from '../../../store/actions/post.action';

interface IAddPostProps {
  postRequest: any;
}

class AddNewPost extends React.Component<IAddPostProps, { postRequest: any }> {
  handleSubmit = (values: any) => {
    console.log(values);
    return this.props.postRequest(values);
  };

  render() {
    return (
      <PostForm
        onSubmit={this.handleSubmit}
        initialValues={{
          postCategories: [],
        }}
        {...{ editMode: false }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, { postRequest })(AddNewPost);
