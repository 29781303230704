import { ProductConstant } from "../actionTypes/product.types.d"
import { IPostAction } from "../models/actions/Post.model"
import { IProductAction } from '../models/actions/Product.model';

export const initialState = {
    pending: false,
    success: false,
    product:{
        submitting:false,
        product:{},
        productCategories:[]
    },
    products: [],
    filter: {
        "include": [
            {
             "relation": "categories"
            }
        ]
    },
fetchType:"product",
message: {},
errors: {},
}

export const ProductReducer = (state = initialState, action: IProductAction) => {
    switch (action.type) {
        case ProductConstant.PRODUCT_FETCH_REQUEST:
            return {
                ...state,
                pending: true,
                success: false,
                filter: action.filter
            }
        case ProductConstant.PRODUCT_FETCH_SUCCEED:
            return {
                ...state,
                pending: false,
                success: true,
                products: action.productList,
            }
        case ProductConstant.PRODUCT_REQUEST:
        return {
            ...state,
            pending: true,
            success: false,
            product: {
                    product:action.product,
                    productCategories: action.product?.productCategories,
                },
        }
        case ProductConstant.PRODUCT_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                message: {
                    body: action.message
                },
                errors: {}
            }
        case ProductConstant.PRODUCT_ERROR:
            return {
                ...state,
                pending: false,
                success: false,
                message: {},
                errors: {
                    body: action.message.toString()
                }
            }
        default:
            return state;
    }
}
